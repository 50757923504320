import { Route, Routes } from 'react-router-dom';
import './App.css';
import Forma from './components/forms/Forma';
import Answ from './components/answ'
import Menu from './components/Menu/Menu'
import StepOneConsulta from './components/Consulta/StepOneConsulta'
import StepNextConsulta from './components/Consulta/StepNextConsulta'
import StepAnalizConsulta from './components/Consulta/StepAnalizConsulta'
import Check from './components/ChecUpp/Check';
import Zakaz from './components/Zakaz';
import Oplata from './components/Oplata';
import FileUpload from './components/FileUpload'
import ZakazAdmin from './components/Admin/ZakazAdmin'
import Adminka from './components/Admin/Adninka';
import ZakazClient from './components/Admin/ZakazClient';
import Spisok from './components/Admin/Spisok';
import Header from './components/Header/Header';
import Inform from './components/Inform';
const headerData={
  sitename: 'SMS',
  nav: [
    {"link":"link1", "text":"my text"},
    {"link":"link2", "text":"my text2"},
    {"link":"link3", "text":"my text3"},
  ]
  }
  
function App() {

  return (
    <div className="App">
{/* 
      <Header props={headerData}/> */}
      <Routes>
      <Route path='/' element={<Menu/>}/>   
      <Route path='/Check' element={<Check/>}/>  
      <Route path='/StepOneConsulta' element={<StepOneConsulta/>}/>  
      <Route path='/StepAnalizConsulta' element={<StepAnalizConsulta/>}/>  
      <Route path='/StepNextConsulta' element={<StepNextConsulta/>}/>   
      <Route path='/Forma' element={<Forma/>}/>  
      <Route path='/Answer' element={<Answ/>}/>  
      <Route path='/Zakaz' element={<Zakaz/>}/>  
      <Route path='/Oplata' element={<Oplata/>}/>  
      <Route path='/up' element={<FileUpload/>}/>    
      <Route path='/admin' element={<Adminka/>}/>    
      <Route path='/ZakazAdmin' element={<ZakazAdmin/>}/> 
      <Route path='/Inform' element={<Inform/>}/> 

  </Routes>

    </div>
  );
}

export default App;
