import React, {useCallback, useEffect, useState} from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './Inform.css'
const PORT='http://localhost:3500'

const Inform = () => {
const [str, setStr]=useState('🔽')
const [str1, setStr1]=useState('🔽')
const [str2, setStr2]=useState('🔽')
const [str3, setStr3]=useState('🔽')
const [str4, setStr4]=useState('🔽')
const [infOne, setInfOne]=useState('none')
const [infTwo, setInfTwo]=useState('none')
const [infTree, setInfTree]=useState('none')
const [infFour, setInfFour]=useState('none')
const [infFive, setInfFive]=useState('none')

function one(){
    if(str==='🔽'){
        setStr('🔼')
        setInfOne('')
    }
  else{  
    setStr('🔽') 
    setInfOne('none')}
}
function two(){
    if(str1==='🔽'){
        setStr1('🔼')
        setInfTwo('')
    }
  else{  
    setStr1('🔽') 
    setInfTwo('none')}
}

function tree(){
    if(str2==='🔽'){
        setStr2('🔼')
        setInfTree('')
    }
  else{  
    setStr2('🔽') 
    setInfTree('none')}
}
function four(){
    if(str3==='🔽'){
        setStr3('🔼')
        setInfFour('')
    }
  else{  
    setStr3('🔽') 
    setInfFour('none')}
}

function five(){
    if(str4==='🔽'){
        setStr4('🔼')
        setInfFive('')
    }
  else{  
    setStr4('🔽') 
    setInfFive('none')}
}



    return (
        <div>

            <Header/>
            <div className='inform'>

      
<hr/>
<button className='BPNRuls' onClick={one}><h2>{str} Політика конфіденційності</h2> </button>
<div className={infOne} style={{backgroundColor:'white'}}>
    <p>Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та іншими законодавчими актами</p>
</div>
<hr/>
<button className='BPNRuls' onClick={two} style={{backgroundColor:'white'}}><h2>{str1} Договір оферти</h2> </button>
<div className={infTwo}>
    <p>Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та іншими законодавчими актами</p>
</div>
<hr/>
<button className='BPNRuls' onClick={tree} style={{backgroundColor:'white'}}><h2>{str2} Умови замовлення та використання послуг </h2> </button>
<div className={infTree}>
    <p>Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та іншими законодавчими актами</p>
</div>
<hr/>
<button className='BPNRuls' onClick={four} style={{backgroundColor:'white'}}><h2>{str3} Умови скасування замовлення </h2> </button>
<div className={infFour}>
    <p>Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та іншими законодавчими актами</p>
</div>
<hr/>
<button className='BPNRuls' onClick={five} style={{backgroundColor:'white'}}><h2>{str4} Умови повернення та виведення грошових коштів </h2> </button>
<div className={infFive}>
    <p>Керуючись положенням Закону України «Про захист персональних даних» від 1 червня 2010 року № 2297-VI, Закону України «Про інформацію» від 2 жовтня 1992 року № 2657-XII, Типового порядку обробки персональних даних затвердженого Наказом Уповноваженого Верховної Ради України з прав людини від 08.01.2014 № 1/02-14 та іншими законодавчими актами</p>
</div>

</div>

<Footer/>
        </div>

        )
    }
export default Inform;