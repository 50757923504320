import React, {useCallback, useEffect, useState} from 'react';

import './Menu/menu.css'
const PORT='https://smsdoctor.online'


const Zakaz = () => {

    const [id, setid] = useState('')
    const [data, setData]=useState('')
    const [dataId, setDataId] = useState('');
    const [dataZakaz, setDataZakaz]=useState('');
    const[dataMenu, setDataMenu]=useState('')
    const [komu, setKomu] = useState(''); 
    const [ nomeOther,setNomeOther]=useState('')
    const [likar, setLikar]=useState('')
    const [skarga, setSkarga]=useState('')
    const [perebig, setPerebig]=useState('')
    const [likuvanny, setLikuvanny]=useState('')
    const [operacia,setOperacia]=useState('')
    const [alergia, setAlergia]=useState('')
    const [hron, setHron]=useState('')
    const [vagitnist, setVagitnist]=useState('')
    const [likiP, setLikiP]=useState('')
    const [dataP, setDataP]=useState('')
    const [timeP, setTimeP]=useState('')
    const [sposib, setSposib]=useState('')
    const [komentar, setKomentar]=useState('')
    const [komButton, setKomButton]=useState('Додати')

    const onChangeKomentar = (e) => {
        setKomentar(e.target.value)
    }
    
    const onChangeid = (e) => {
        setid(e.target.value)
    }

    function Click(event){
        event.preventDefault();
        fetch(PORT, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ 
            "action": 3,
             "id" : id,
             
          }),
      })
          .then(response => response.json())
          .then(response => {
            const data=response[0]
            const a=data.id
            const z=data.DataToDay
            const b=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+z[8]+z[9]
            const c=data.menu
            const d=data.komu
            const e=data.pacient
            const f=data.likar
            const k=data.skarga
            const l=data.perebig
            const m=data.likuvanny
            const n=data.operacia
            const o=data.alergia
            const p=data.hron
            const r=data.vagitnist
            const s=data.likiP
            const jj=Math.round(z[8]+z[9])+data.dia
            const t=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+jj
            const v=data.time
            const w=data.sposibText

            setDataId(a)
            setDataZakaz(b)
            setDataMenu(c)        
            setKomu(d)
            setNomeOther(e)
            setLikar(f)
            setSkarga(k)
            setPerebig(l)
            setLikuvanny(m)
            setOperacia(n)
            setAlergia(o)
            setHron(p)
            setVagitnist(r)
            setLikiP(s)
            setDataP(t)
            setTimeP(v)
            setSposib(w)

        
          })

      }
 function Edd(event){
  
    event.preventDefault();
    fetch(PORT, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ 
        "action": 4,
        "id":  id,
        "komentar": komentar,
    
    }),
  })
      .then(response => response.text())
      .then(response => {
const a=response
if(a===id){
    alert('Коментар додано')
    setKomButton('Внести зміни')
}else if(a===0){
    alert('Коментар не додано, зверніться до адміністратора') 
}
else{
    alert('Коментар не додано, зверніться до адміністратора')  
}
      }  
 )}
    return (
        <div className={"Doctor"}>
<div className='DocInput'>
          Id консультації
            <input
                className='inputDoc'
                type="textarea"
                placeholder={'id клієнта'}
                value={id}
                onChange={onChangeid}
                
            />
            <button className={"btnMiniDoc"} onClick={Click}> ПЕРЕГЛЯНУТИ </button>
            </div>

       {/* <p>  {data}</p>    */}
<p>ID замовлення: {dataId}</p>
<p>Додано замовлення: {dataZakaz}</p>
<p>Послуга: {dataMenu}</p>
<p>Послуга: {komu}</p>
<p>{nomeOther}</p>
<p>Лікар: {likar}</p>
<p>Скарги пацієнта: {skarga}</p>
<p>Перебіг хвороби: {perebig}</p>
<p>Лікування: {likuvanny}</p>

    <ul>
        <li>Перенесені операції:  {operacia}</li>
        <li>Хронічні захворювання: {hron}</li>
        <li>Наявність алергії: {alergia}</li>
        <li>Вагітність та період лактації: {vagitnist}</li>
        <li>Прийом ліків на постійній основі: {likiP} </li>
    </ul>
  
<p>Дата консультації: {dataP}</p>
<p>Час консультації: {timeP}:00</p>
<p>Спосіб звязку: {sposib}</p>
<div className='DocInput'>
        Коментар лікаря
            <textarea
                className='inputDoc'
                type="textarea"
                placeholder={'id клієнта'}
                value={komentar}
                onChange={onChangeKomentar}
                
            />
            <button className={"btnMiniDoc"} onClick={Edd}> {komButton} </button>
            </div>

        </div>

        )
    }
export default Zakaz;