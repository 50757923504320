
import React, { useState, useCallback,useEffect, useRef} from "react";
import { useTelegram } from "../hooks/useTelegram";
import FileUpload from "../FileUpload";
const PORT='https://smsdoctor.online'


function StepOneConsulta(props){
    const y=props.id
    const [clientIdZakaz, setClientIdZakaz]=useState('')
    const [t2, setT2] = useState('');
    const [consultaTime, setConsultaTime]=useState('dataConsulta')
    const [consultaTime1, setConsultaTime1]=useState('dataConsulta')
    const [consultaTime2, setConsultaTime2]=useState('dataConsulta')
    const [consultaTime3, setConsultaTime3]=useState('dataConsulta')
    const [consultaPrioritet, setConsultaPrioritet]=useState('consultaPrioritet')
    const [consultaData, setConsultaData]=useState('dataConsulta')
    const [consultaData1, setConsultaData1]=useState('dataConsulta')
    const [consultaData2, setConsultaData2]=useState('dataConsulta')
    const [consultaData3, setConsultaData3]=useState('dataConsulta')
    const [consultaData4, setConsultaData4]=useState('dataConsulta')
    const [consultaData5, setConsultaData5]=useState('dataConsulta')
    const [consultaData6, setConsultaData6]=useState('dataConsulta')
    const [consultaData7, setConsultaData7]=useState('dataConsulta')
    const [consultaData8, setConsultaData8]=useState('dataConsulta')
    const [consultaData9, setConsultaData9]=useState('dataConsulta')
    const [consultaData10, setConsultaData10]=useState('dataConsulta')
    const [consultaData11, setConsultaData11]=useState('dataConsulta')
    const [consultaData12, setConsultaData12]=useState('dataConsulta')
    const [consultaData13, setConsultaData13]=useState('dataConsulta')
    const [consultaData14, setConsultaData14]=useState('dataConsulta')
    const today=new Date().getUTCDate()
    const month= new Date().getMonth()+1
    const textData=`${today}.0${month}`
    const textData1=`${today+1}.0${month}`
    const textData2=`${today+2}.0${month}`
    const textData3=`${today+3}.0${month}`
    const textData4=`${today+4}.0${month}`
    const textData5=`${today+5}.0${month}`
    const textData6=`${today+6}.0${month}`
    const textData7=`${today+7}.0${month}`
    const textData8=`${today+8}.0${month}`
    const textData9=`${today+9}.0${month}`
    const textData10=`${today+10}.0${month}`
    const textData11=`${today+11}.0${month}`
    const textData12=`${today+12}.0${month}`
    const textData13=`${today+13}.0${month}`
    const textData14=`${today+14}.0${month}`
    const goduna=new Date().getHours()
    const hvuluna= new Date().getMinutes()
    const [grn, setGrn]=useState('800')
    const sends='SYMPTOM'
    const [send, setSend]=useState('none')
    const [dia, setDia]=useState('')
    const [time, setTime]=useState('')
    const [timeNow, setTimeNow]=useState(goduna)


    const [backStart, setBackStart]=useState('backBTN')
    const [textBTN, setTextBTN]=useState('Далі ➡️')
    const [BTNBig, setBTNBig]=useState('BTNBig')
    const [strocaDoctor, setStrocaDoctor]=useState('stroca')
    const [strocaPediatr, setStrocaPediatr]=useState('none') 
    const [back, setBack]=useState('none')
    const [doctor,setDoctor]=useState('btnMinilikarActive')
    const [terapevt, setTerapevt]=useState('btnMinilikar')
    const [gastro, setGastro]=useState('btnMinilikar')
    const [kardio, setKardio]=useState('btnMinilikar')
    const [endo, setEndo]=useState('btnMinilikar')
    const [derma, setDerma]=useState('btnMinilikar')
    const [ginek, setGinek]=useState('btnMinilikar')
    const [spec, setCpec]=useState('за симптомами')
    const [pediatr, setPediatr]=useState('btnMinilikar')
    const[bebyDermatolog, setBebyDermatolog] =useState('btnMinilikar')
    const [one, setOne]=useState('btnMinilikar')
    const [inputDiv, setInputDiv]=useState('none')
    const [inputDiv1, setInputDiv1]=useState('none')
    const [inputDiv2, setInputDiv2]=useState('none')
    const [inputDiv3, setInputDiv3]=useState('none')
    const [five, setFive]=useState('btnMinilikar')  
    const [fo, setFo]=useState('btnMinilikar') 
    const [thry, setThry]=useState('btnMinilikar')
    const [two, setTwo]=useState('btnMinilikar')
    const [likyes, setLikyes]=useState('btnMinilikaR')
    const [likno, setLikno]=useState('btnMinilikaR')
    const [inputDivLik, setInputDivLik]=useState('none')
    const [good, setGood]=useState('btnMinilikaR')
    const [bad, setBad]=useState('btnMinilikaR')
    const [normal, setNormal]=useState('btnMinilikaR')
    const [inputDinamika, setInputDinamika]=useState('none')
    const [step0, setStep0]=useState('blockGrande') 
    const [step1, setStep1]=useState('none')
    const [step2, setStep2]=useState('none')
    const [step3, setStep3]=useState('none')  
    const [step4, setStep4]=useState('none') 
    const [step5, setStep5]=useState('none')  
    const [consultaBig, setConsultaBig]=useState('btnMinilikar')
    const [consultaBebe, setConsultabebe]=useState('btnMinilikar')
    const [consultaMe, setConsultaMe]=useState('btnMinilikarActive')

    const [formaBebe, setFormaBebe]=useState('none')
    const [formaBig, setFormaBig]=useState('none')
    const [men, setMen]=useState('btnMinilikar')
    const [women, setWomen]=useState('btnMinilikar')

    const {tg} = useTelegram();
    const [nome, setNome]=useState('')

    const [stat, setStat]=useState('')
    const [phone, setPhone]=useState('')
    const [skarga, setSkarga]=useState('')
    const [perebig, setPerebig]=useState('')
    const [perebigText, setPerebigText]=useState('')
    const [likyvannyYesorNo, setLikyvannyYesorNo]=useState('')
    const [likText, setLikText]=useState('')
    const [sposibText, setSposibText]=useState('')

    const [date, setDate] = useState();

    const [inputformaBebe, setInputformaBebe]=useState('inputformaBebe')
    const [inputformaData, setInputformaData]=useState('inputformaBebe')
    const [textNome, setTextNome]=useState('')
 const [textBack, setTextBack]=useState('')
    const [eksrtenno, setEksrtenno]=useState('eksrtenno')
const [komu, setKomu]=useState('Первинна консультація для мене')
const [likipostiyno, setLikipostiyno]=useState('')
const [operacia,  setOperacia]=useState('')
const [hron, setHron]=useState('')
const [alergia, setAlergia]=useState('')
const [vagitnist ,setVagitnist]=useState('Ні')
const ir=dia.toString()

const [chSt, setChSt]=useState('')

const onChangeAlergia = (e) => {
    setAlergia(e.target.value)
}


const onChangeHron = (e) => {
    setHron(e.target.value)
}

const onChangeLikiPostiyno = (e) => {
    setLikipostiyno(e.target.value)
}

const onChangeOperacia = (e) => {
   setOperacia(e.target.value)
}


const onChangeSposib= (e) => {
    setSposibText(e.target.value)

}

    const onChangeLik = (e) => {
        setLikText(e.target.value)
   
    }

    const onChangePerebig = (e) => {
        setPerebigText(e.target.value)
    }
    const onChangeSkarga = (e) => {
        setSkarga(e.target.value)
    }

    const onChangeNome=(e)=>{
    
        setNome(e.target.value) 
        if(e.target.value.length<2){
        const t='Будь ласка, напишіть коректні прізвище та і`мя'
        const b= 'inputformaBebered'
        setTextNome(t)
        setInputformaBebe(b)
        }else  {
        const t=''    
        const b= 'inputformaBebe'
        setInputformaBebe(b)
        setTextNome('')
        } 
    }

    const onChangeDate=(e)=>{
        setDate(e.target.value)
        const regexddmmyyyy = /^(0?[1-9]$)|(^1[0-9]$)|(^2[0-9]$)|(^3[0-1]$)/
     
        // if (regexddmmyyyy.test(date)===true) {
        //     const a=' '
        //     const b= 'inputformaBebe'
        //     setInputformaData(b)
        //     setTextData(a)
        // } else {
        //     const b= 'inputformaBebered'
        //     const a='Будь ласка, вкажить вірну дату народження в форматі день/місяць/рік нарождення'
        //     setInputformaData(b)
        //     setTextData(a)
        // }
    } 
    const onChangePhone=(e)=>{
        
        setPhone(e.target.value)
        
    } 
 
    function Steps (event) {
        event.preventDefault();
        fetch(PORT, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ 
            "action": 2,
             "id":    props.id,
             "menu" : "ПЕРВИННА КОНСУЛЬТАЦІЯ ЛІКАРЯ",
             "komu" : komu,
             "pacient" : nome+ date+phone+stat,
             "date": date,
             "likar": spec, 
             "skarga": skarga ,
             "perebig": perebig + '/n'+perebigText, 
             "likyvan": likyvannyYesorNo + likText, 
             "operacia": operacia,
             "hron":hron,
             "alergia": alergia,
             "vagitnist": vagitnist, 
             "likiP": likipostiyno,
             "dia" : dia,
             "time":time,
             "sposibText":sposibText

        }),
      })
          .then(response => response.text())
          .then(response => {
              console.log(response);
              setT2(response.toString());
              setClientIdZakaz(response.toString())
            
          })
       
    
            const a='blockGrande'
            const b='none'
            const l=''
            // const d='Редагувати'
            const m='⬅️ Назад' 
            if(step0==='blockGrande'){
                setStep0(b)
                setStep1(a)
                setTextBack(m)
                setBack('backBTN')
                setBackStart('none')
            }
            if(step1==='blockGrande'){
                setStep1(b)
                setStep2(a) 
                setTextBack(m)
        
            }
            if(step2==='blockGrande'){
                setStep2(b)
                setStep3(a) 
                setTextBack(m)
              
            }
            if(step3==='blockGrande'){
                setStep3(b)
                setStep4(a) 
                setTextBack(m)
              
            } 
            if(step4==='blockGrande'){
                setStep4(b)
                setStep5(a)
                setTextBack(m)
              
                // setTextBTN(d)
                
            }
            
            if(step5==='blockGrande'){
                setStep3(a)
                setStep0(a)
                setStep1(a)
                setStep2(a)
                setStep4(a)
                setTextBack(m)
                setBTNBig(b)
                setSend('yes')
                setTextBack(l)
                setChSt('redact')
            } 
        
      }
      
      
    const onSendData = useCallback(() => {
    const data = {
        sends,
        clientIdZakaz,
        grn
       
    }
    tg.sendData(JSON.stringify(data));
}, [ sends, clientIdZakaz, grn])   



useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData)
    return () => {
        tg.offEvent('mainButtonClicked', onSendData)
    }
}, [onSendData])

useEffect(() => {
    if (chSt==='') {
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
}, [chSt])

useEffect(() => {
       tg.MainButton.setParams({
       text: 'НАДІСЛАТИ ЗАМОВЛЕННЯ'
    

})})


function ConsultaMe(){
    const a='Первинна консультація для мене'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='none'
    const d='stroca'
    setConsultaMe(b)
    setConsultaBig(c)
    setConsultabebe(c) 
    setFormaBebe(act) 
    setFormaBig(act)
    setStrocaPediatr(act)
    setStrocaDoctor(d)
    setKomu('a')
}

function ConsultaBebe(){
    const a='Первинна консультація для дитини'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='formaBebe'
    const v='none'
    const d='stroca'
    setConsultaMe(c)
    setConsultaBig(c)
    setConsultabebe(b) 
    setConsultaMe(c)
    setFormaBebe(act)
    setFormaBig(v) 
    setStrocaPediatr(d)
    setStrocaDoctor(v)
    setKomu(a)
}
function ConsultaBig(){
    const a='Первинна консультація для іншого дорослого'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='formaBebe'
    const v='none'
    const d='stroca'
    setConsultaMe(c)
    setConsultaBig(b)
    setConsultabebe(c) 
    setConsultaMe(c)
    setFormaBig(act)
    setFormaBebe(v) 
    setStrocaPediatr(v)
    setStrocaDoctor(d) 
    setKomu(a)
}
  
    function notDoctor(){
        const a='за симптомами'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(b)
        setTerapevt(c)
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c) 
        setCpec(a) 
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Terapevt(){
        const a='Терапевт'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(b) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Gastro(){
        const a='Гастроінтеролог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(b)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }
    function Kardio(){
        const a='Кардіолог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(b)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Endo(){
        const a='Ендокринолог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(b)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }
    function Derma(){
        const a='Дерматолог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(b)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Ginek(){
        const a='Геніколог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(b)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }
    

    function Pediatr(){
        const a='Педіатр'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setBebyDermatolog(c)
        setPediatr(b)
    }
    function BebyDermatolog (){
        const a='Дитячий дерматолог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setBebyDermatolog(b)
    }

function One(){
    if(one==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setOne(b)
        setInputDiv(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setOne(b) 
        setInputDiv(c)

    }
}
function Two(){
    if(two==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setTwo(b)
        setInputDiv1(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setTwo(b) 
        setInputDiv1(c)

    }
}

function Thry(){
    if(thry==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setThry(b)
        setInputDiv2(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setThry(b) 
        setInputDiv2(c)

    }
}
function Fo(){
    if(fo==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setFo(b)
        setInputDiv3(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setFo(b) 
        setInputDiv3(c)

    }
}

function Five(){
const a='Так'
const n='Ні'
    if(five==='btnMinilikar'){
        const b='btnMinilikarActive'
        setFive(b)
        setVagitnist(a)
    }else{
        const b='btnMinilikar'
        setVagitnist(n)
        setFive(b) 
    }
}
function LikYes(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a="Так, отримую"
    setLikyvannyYesorNo (a)
    setLikyes(b)
    setLikno(c)
    setInputDivLik(d)
}

function LikNo(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='none'
    const a="Ні, не отримую"
    setLikyvannyYesorNo (a) 
    setLikyes(c)
    setLikno(b)
    setInputDivLik(d)
}
function Good(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a='Мені вже краще' 
    setPerebig(a)
    setGood(b)
    setNormal(c)
    setBad(c)
    setInputDinamika(d)
}
function Bad(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a='Мені стало гірше' 
    setPerebig(a) 
    setGood(c)
    setNormal(c)
    setBad(b)
    setInputDinamika(d) 
}

function Normal(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='none' 
    const a='Все без змін' 
    setPerebig(a)
    setGood(c)
    setBad(c)
    setNormal(b)
    setInputDinamika(d) 
}


//  function Steps(){
//     const a='blockGrande'
//     const b='none'
//     const l=''
//     const d='Редагувати'
//     const m='⬅️ Назад' 
//     if(step0==='blockGrande'){
//         setStep0(b)
//         setStep1(a)
//         setTextBack(m)
 
//     }
//     if(step1==='blockGrande'){
//         setStep1(b)
//         setStep2(a) 
//         setTextBack(m)

//     }
//     if(step2==='blockGrande'){
//         setStep2(b)
//         setStep3(a) 
//         setTextBack(m)
      
//     }
//     if(step3==='blockGrande'){
//         setStep3(b)
//         setStep4(a) 
//         setTextBack(m)
      
//     } 
//     if(step4==='blockGrande'){
//         setStep4(b)
//         setStep5(a)
//         setTextBack(m)
//         setTextBTN(d)
//     }
    
//     if(step5==='blockGrande'){
//         setStep3(a)
//         setStep0(a)
//         setStep1(a)
//         setStep2(a)
//         setStep4(a)
//         setTextBack(m)
//         setBTNBig(b)
//         setSend('yes')
//         setTextBack(l)
//     } 

// }
 function Back(){
    const a='⬅️ Назад' 
    const b='blockGrande'
    const c='none' 
    const v='Далі ➡️'
    if(step1==='blockGrande'){
 
        setStep1(c) 
        setStep0(b)
        setTextBack(a)
        setBack('none')
        setBackStart('backBTN')
    } 

    if(step2==='blockGrande'){
        setBackStart('none') 
        setTextBack(a)
        setStep1(b)
        setStep2(c) 
       

    }
    if(step3==='blockGrande'){
       
        setTextBack(a)
   
        setStep2(b)
        setStep3(c) 
    }
    if(step4==='blockGrande'){
       
        setTextBack(a) 
        setStep3(b)
        setStep4(c)
    }
    if(step5==='blockGrande'){
        setTextBack(a) 
        setTextBTN(v)
        setStep4(b)
        setStep5(c)
    } 
 }

 function Men(){
    if(men==='btnMinilikar'){
        const b='btnMinilikarActive'
        const a='btnMinilikar'
        const c='чоловіча'
        setStat(c)
        setMen(b)
        setWomen(a)

    }else{
        const b='btnMinilikar'
        const a='btnMinilikarActive'
        const c='жіноча'
        setStat(c)
        setMen(b)
        setWomen(a)

    }
}

function Women(){
    if(women==='btnMinilikar'){
        const b='btnMinilikarActive'
        const a='btnMinilikar'
        const c='жіноча'
        setStat(c)
        setWomen(b)
        setMen(a)

    }else{
        const b='btnMinilikar'
        const a='btnMinilikarActive'
        const c='чоловіча'
        setStat(c)
        setMen(a)
        setWomen(b)

    }
}



function dataConsulta(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c={textData}
    const e='dataConsultaGrey'
    const v ='consultaPrioritet'
    const goduna=new Date().getHours()
    setTimeNow(goduna+1)
      
            if(timeNow<5){
                setConsultaTime(a)
                setConsultaTime1(a)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v)  
            }else
            if(timeNow<8){
                setConsultaTime(e)
                setConsultaTime1(a)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v) 
            }else 
            if(timeNow<11){
                setConsultaTime(e)
                setConsultaTime1(e)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v) 
            }else
            if(timeNow<14){
            setConsultaTime(e)
            setConsultaTime1(e)
            setConsultaTime2(e)
            setConsultaTime3(a) 
            setConsultaPrioritet(v) 
        }else
        if(timeNow>14){
            setConsultaTime(e)
            setConsultaTime1(e)
            setConsultaTime2(e)
            setConsultaTime3(e) 
            setConsultaPrioritet(v)  
        }
    setDia(c)
    setConsultaData(b)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
 





}


function dataConsulta1(){

    const c=1
    const v ='consultaPrioritet' 
    const a='dataConsulta'
    const b='dataConsultaActive'

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  
    setDia(c)

    
    setConsultaData(a)
    setConsultaData1(b)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta2(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=2
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  
   
    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(b)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta3(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=3
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(b)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta4(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=4
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(b)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta5(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=5
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(b)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta6(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=6
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(b)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta7(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=7
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(b)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta8(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=8
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(b)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta9(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=9
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(b)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta10(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=10
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(b)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta11(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=11
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(b)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta12(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=12

    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(b)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta13(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=13

    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(b)
    setConsultaData14(a)
}
function dataConsulta14(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=14
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(14)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(b)
}

function timeConsulta(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const r='1000'
    const t='Пріорітетна консультація'
    const v='dataConsultaGrey'


    if(consultaTime==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
    }else
    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    }else{
        setConsultaTime(a)
        setConsultaTime1(a)
        setConsultaTime2(a)
        setConsultaTime3(a)
    }
    setTime(t)
    setConsultaPrioritet(d)
    setGrn(r)
}
function timeConsulta1(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const v='dataConsultaGrey'

    const t='8:00-11:00'
    if(consultaTime==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
    }else
    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    } else{
        setTime(t)
        setConsultaTime(b)
        setConsultaTime1(a)
        setConsultaTime2(a)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }
  

}
function timeConsulta2(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const j='dataConsultaGrey'
    const t='11:00-14:00'
    const v='dataConsultaGrey'

    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    }else{
        setTime(t)
        setConsultaTime(a)
        setConsultaTime1(b)
        setConsultaTime2(a)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }



}
function timeConsulta3(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const t='14:00-17:00'
    const v='dataConsultaGrey'

    if(consultaTime2==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)
        setConsultaTime2(v)
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaTime3(v)
        }else{
            setConsultaTime3(a) 
        }
    }
   else
    {
        setTime(t)
        setConsultaTime(a)
        setConsultaTime1(a)
        setConsultaTime2(b)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }
    

}
function timeConsulta4(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive'
    const t='17:00-20:00'

if(consultaTime3==='dataConsultaGrey'){
    setConsultaPrioritet(c) 
} else{
    setTime(t) 
    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(b) 
    setConsultaPrioritet(c)
}

 
}

// function colorTime(){
//     const goduna=new Date().getHours()
//     const hvuluna= new Date().getMinutes()
    
//     if(consultaData==='dataConsultaActive')
//         {
//         if (hvuluna>0)
//             { 
//                 const a=goduna+1
//                 setTimeNow(a)
//                 console.log(timeNow)
//                 if(timeNow<5){
//                     console.log('1')
//                 }
//                 if(timeNow<8){
//                     console.log('2')
//                 }
//                 if(timeNow<11){
//                     console.log('3')
//                 }
//                 if(timeNow<14){
//                     console.log('4')
//                 }
//                 if(timeNow<17){
//                     console.log('5')
//                 }
//                 if(timeNow>17){
//                     console.log('6')
//                 }
//             }
    
//         else
//             {
//             const a=goduna
//             setTimeNow(a)
//             console.log(timeNow)
//             }
//     }
  
 

// }


function ekstr(){
    const a='buttonNextDA'
    setEksrtenno(a)
}
    return(
<>

{/* назад */}
{/* <button onClick={colorTime}>nnnnnnnnn</button> */}

<div className="BTNSET" >

    <button className={backStart}><h2><a href="/">⬅️</a></h2></button>
    <button className={back} onClick={Back} ><h2>{textBack}</h2></button>
    <button className={BTNBig} onClick={Steps}><h2>{textBTN}</h2></button>
</div>


<div className={eksrtenno}>
<h4>Якщо це екстрена ситуація, та Ви знаходитеся в Україні - негайно телефонуйте за номером 103</h4>
    <button className="exrtBTN" onClick={ekstr}>Далі</button>
</div>


<div autoFocus className={step0}>
    
1. Кому потрібна консультація?
<div className="stroca">
    <button className={consultaMe} onClick={ConsultaMe}>
    </button>
     <div className="leftl">Мені</div>
             </div>

        <div className='stroca'> <button className={consultaBebe} onClick={ConsultaBebe}>
        </button>
        <div className="leftl">Моїй дитині</div>
        </div>
   
    <div className={formaBebe}>
    <h5> ПІБ дитини</h5>
    <p color="red">{textNome}</p>
<input type='text'
className={inputformaBebe}
placeholder="Іванов Петро"
value=  {nome}  
onChange={e=>onChangeNome(e)}
/>

<h5> Дата народження дитини</h5>
<input type='text'
className={inputformaData}
placeholder="09.02.2020"
value={date}  
onChange={e=>onChangeDate(e)}
/>

<h5>Стать пацієнта</h5>
<div className='rydok'>
    <div className='strocamin'>
        <button className={men} onClick={Men}>
        </button>
        <div className="leftlR">Чоловіча</div>
        </div> 
   
        <div className='strocamin'>
        <button className={women} onClick={Women}>
        </button>
     <div className="leftlR">Жіноча</div> 
     </div> 

     </div>
    </div> 

        <div className='stroca'>
        <button className={consultaBig} onClick={ConsultaBig}>
        </button>
        <div className="leftl">Іншому дорослому</div>
        </div> 


        <div className={formaBig}>
    <h5> ПІБ пацієнта</h5>
    <p color="red">{textNome}</p>
<input type='text'
className={inputformaBebe}
placeholder="Петров Борис"
value=  {nome}  
onChange={e=>onChangeNome(e)}
/>

<h5> Дата народження пацієнта</h5>

<input type='text'
className={inputformaData}
placeholder="09.02.1987"
value={date}  
onChange={e=>onChangeDate(e)}
/>
<h5>Контактний номер пацієнта</h5>
<input type='text'
className='inputformaBebe'
placeholder="380990000000"
value={phone}  
onChange={e=>onChangePhone(e)}
/>
<div className='rydok'>
    <div className='strocamin'>
    <h5>Стать пацієнта</h5>
        <button className={men} onClick={Men}>
        </button>
        <div className="leftlR">Чоловіча</div>
        </div> 
   
        <div className='strocamin'>
        <button className={women} onClick={Women}>
        </button>
     <div className="leftlR">Жіноча</div> 
     </div> 

     </div>
    </div> 
    <div className="vstavka"></div>

</div>

<div className={step1}>
2. Будь ласка, оберіть лікаря 

<div className='stroca'>
    <button className={doctor} onClick={notDoctor}>
    </button>
     <div className="leftl">Не можу визначитися</div>
             </div>

        <div className={strocaDoctor}> <button className={terapevt} onClick={Terapevt}>
        </button>
        <div className="leftl">Терапевт</div>
        </div>

        <div className={strocaDoctor}>
        <button className={gastro} onClick={Gastro}>
        </button>
        <div className="leftl">Гастроінтеролог</div>
        </div> 

        <div className={strocaDoctor}>
        <button className={kardio} onClick={Kardio}>
        </button>
     <div className="leftl">Кардiолог</div> 
     </div> 


      <div className={strocaDoctor}> 
        <button className={endo} onClick={Endo}>
        </button>
     <div className="leftl">Eндокринолог</div> 
     </div> 

        <div className={strocaDoctor}>
        <button className={derma} onClick={Derma}>
        </button>
     <div className="leftl">Дерматолог</div> 
     </div> 
     <div className={strocaPediatr}>
        <button className={pediatr} onClick={Pediatr}>
        </button>
     <div className="leftl">Педіатр</div> 
     </div>
     <div className={strocaPediatr}>
        <button className={bebyDermatolog } onClick={ BebyDermatolog }>
        </button>
     <div className="leftl">Дитячий дерматолог</div> 
     </div>
    
        <div className={strocaDoctor}>
        <button className={ginek} onClick={Ginek}>
        </button>
     <div className="leftl">Гiнеколог
</div>
</div>
<div className="vstavka"></div> 
</div>

{/* Які прояви хвороби були на початку, як змінювалися симптоми з часом і як Ви почуваєте себе зараз? */}
<div className={step2}>
3. Які прояви хвороби були на початку, як змінювалися симптоми з часом і як Ви почуваєте себе зараз?
  
    <h5>Опишіть Ваші скарги</h5>
    <div className='blockMini1'>
<textarea
className='inputTextB'
wrap="soft" 
name='skarga'
placeholder="Три дні тому з'явилася нежить
 та головний біль.Bчора з'явилася температура
  39 С, сьогодні непокоїть головний біль та
температура 37,8 С зранку"
value={skarga}  
onChange={e=>onChangeSkarga(e)}
/>
    </div>
    <div> <h5>🖇 За необхидности додайте фото та/або видео</h5>
    <FileUpload id={props.id}/>
   {/* <input 
   type="file"/> */}
   </div>
               <div className="vstavka"></div> 

</div>


{/* 3. Опишіть перебіг та динаміку хвороби */}
<div className={step3}>
4. Опишіть перебіг та динаміку хвороби
     <h5>Перебіг хвороби</h5>
     {/* <div className='rydok'> */}
    <div className='stroca'>
        <button className={good} onClick={Good}>
        </button>
        <div className="leftlR">Покращення</div>
        </div> 
        <div className='stroca'>
        <button className={bad} onClick={Bad}>
        </button>
     <div className="leftlR">Погіршення</div> 
     </div> 
     <div className='stroca'>
        <button className={normal} onClick={Normal}>
        </button>
     <div className="leftlR">Без змін</div> 
     </div> 
     {/* </div> */}
     <div className={inputDinamika}>
     <textarea
     className='inputText'
     placeholder="Що саме?"
     value=  {perebigText}  
     onChange={e=>onChangePerebig(e)} 
     /> 
     </div>
     <h5>Чи отримуєте Ви лікування?</h5>
        {/* <div className='rydok'> */}
    <div className='strocamin'>
        <button className={likyes} onClick={LikYes}>
        </button>
        <div className="leftlR">Так</div>
        </div> 
   
        <div className='strocamin'>
        <button className={likno} onClick={LikNo}>
        </button>
     <div className="leftlR">Ні</div> 
     </div> 

     {/* </div> */}
     <div className={inputDivLik}>
     <textarea
     className='inputText'
     placeholder="Яке саме?"
     value=  {likText}  
     onChange={e=>onChangeLik(e)} 
     /> 
     </div>
     <div className="vstavka"></div>

</div>

{/*  Важливі питання */}
<div className={step4}>

5. Особливі стани(якщо є в наявності)

    <div className='stroca'> 
        <button className={one} onClick={One}>
        </button>
     <div className="leftl">Наявність алергії та непереносимість ліків</div> 
     </div> 
     <div className={inputDiv}>
     <textarea
     className='inputText'
     placeholder="алергія не пенецілін"
     value=  {alergia}  
     onChange={e=>onChangeAlergia(e)} 
     /> 
     </div>

     <div className='stroca'> 
        <button className={two} onClick={Two}>
        </button>
     <div className="leftl">Наявність хронічних захворювань</div> 
     </div> 
     <div className={inputDiv1}>
     <textarea
     className='inputText'
     placeholder="підвищений тиск"
     value=  {hron}  
     onChange={e=>onChangeHron(e)} 
     /> 
     </div>

     <div className='stroca'> 
        <button className={thry} onClick={Thry}>
        </button>
     <div className="leftl">Перенесені операції</div> 
     </div> 
     <div className={inputDiv2}>
     <textarea
     className='inputText'
     placeholder="видалена щитоподібна залоза"
     value=  {operacia}  
     onChange={e=>onChangeOperacia(e)} 
     /> 
     </div>
     <div className='stroca'> 
        <button className={fo} onClick={Fo}>
        </button>
        <div className="leftl">Прийом ліків на постійній основі</div> 
             </div> 
     <div className={inputDiv3}>
     <textarea
     className='inputText'
     placeholder="я приймаю постійно еутірокс 75 мг"
     value=  {likipostiyno}  
     onChange={e=>onChangeLikiPostiyno(e)

     } 
     /> 
     </div>
     <div className='stroca'> 
        <button className={five} onClick={Five}>
        </button>
        <div className="leftl">Вагітність та період лактації</div> 
             </div> 
             <div className="vstavka"></div>

</div>
<div className={step5}>
6. Оберіть бажані дату та час консультації
<h5>
        Дата
    </h5>
    <div className='blockData'>
        <button className={consultaData} onClick={dataConsulta}>{textData}</button>
        <button className={consultaData1} onClick={dataConsulta1}>{textData1}</button>
        <button className={consultaData2} onClick={dataConsulta2}>{textData2}</button>
        <button className={consultaData3} onClick={dataConsulta3}>{textData3}</button>
        <button className={consultaData4} onClick={dataConsulta4}>{textData4}</button>
        <button className={consultaData5} onClick={dataConsulta5}>{textData5}</button>
        <button className={consultaData6} onClick={dataConsulta6}>{textData6}</button>
        <button className={consultaData7} onClick={dataConsulta7}>{textData7}</button>
        <button className={consultaData8} onClick={dataConsulta8}>{textData8}</button>
        <button className={consultaData9} onClick={dataConsulta9}>{textData9}</button>
        <button className={consultaData10} onClick={dataConsulta10}>{textData10}</button>
        <button className={consultaData11} onClick={dataConsulta11}>{textData11}</button>
        {/* <button className={consultaData12} onClick={dataConsulta12}>{textData12}</button>
        <button className={consultaData13} onClick={dataConsulta13}>{textData13}</button>
        <button className={consultaData14} onClick={dataConsulta14}>{textData14}</button> */}
     </div>
   
     <h5>Час</h5>
     <div className="blockChas">
   
      <button className={consultaPrioritet} onClick={timeConsulta}>Пріорітетна консультація <span className="red">*</span></button> 
     <button className={consultaTime} onClick={timeConsulta1}>08.00-11.00</button>
        <button className={consultaTime1} onClick={timeConsulta2}>11.00-14.00</button>
        <button className={consultaTime2} onClick={timeConsulta3}>14.00-17.00</button>
        <button className={consultaTime3} onClick={timeConsulta4}>17.00-20.00</button>
    <p><span className="red">*</span>Пріорітетна консультація - консультація протягом двох годин. Ії вартість на 200грн дорожча.</p>

     </div>
     <h5>Яким способом Вам зручно отримати консультацію?</h5>
     {/* </div> */}
     <div className='inputDiv'>
     <textarea
     className='inputText'
     placeholder="За номером телефону: +380976664453"
     value=  {sposibText}  
     onChange={e=>onChangeSposib(e)

        
     } 
     /> 
     </div>
     <div className="vstavka"></div>

</div>
<div className="vstavka"></div>
<div className="vstavka"></div>
</>
    )
}

export default StepOneConsulta