import React, {useCallback, useEffect, useState} from 'react';

import './zakaz.css'
import ZakazAdmin from './ZakazAdmin';
const Port2='https://smsdoctor.online'


const Adminka = () => {

    const [show, setShow] = useState('none')
    const [login, setLogin]=useState('')
    const [password, setPassword]=useState('')
    const [loginBlock, setLoginBlock]=useState('loginBlock')
    const [menuAdmin, setMenuAdmin]=useState('none')
    const[test, setTest]=useState('j')

    const onChangeLogin = (e) => {
        setLogin(e.target.value)
    }
    
    const onChangePassword = (e) => {
        setPassword(e.target.value)
    }
    function Click(event){
        event.preventDefault();
        fetch(Port2, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ 
            "action": 7,
            "login" :login,
            "password": password
          }),
      })
          .then(response => response.text())
          .then(response => {
            const data=response
    
            if(data==='"admin"'){
                setShow(data)
                setLoginBlock('none')
                setMenuAdmin('menuAdmin')
            }
        else{
            alert('Ви ввели не вiрнi логiгн/пароль')
        }
            
          })

      } 


    return (

<div className='blank'>
<p className='textDoc'>    Online Servis SMS   
“Support Medikal System”  -     
📞 +380985870303, +380955870303     - 
   🌐 www.doctor.kharkov.ua </p>
    <div className='blockHeader'>
        <div className='blockLogo'>
        <button className='logo'><h2>SMS</h2></button>
        </div>


    <block className={menuAdmin}>
    <button className='headerAdminMenu'><h2>Замовлення</h2></button>  
    <button className='headerAdminMenu' ><h2>Календар</h2></button> 
    </block>



    </div>

<div className={loginBlock}>
    <h3 className='textLogin'>
        ВХІД
    
    </h3>

    <h3 className='textLogin'>
    <input 
className='inputLoginBlock'
type="text" 
placeholder='login'
value={login}
onChange={onChangeLogin}
/>
    </h3> 
    <h3 className='textLogin'>
<input
className='inputLoginBlock'
 type="text" 
placeholder='password'
value={password}
onChange={onChangePassword}
/>
</h3>
<h3 className='textLogin'>
<button className='buttonLoginBlock'  onClick={Click}>ВХІД</button>
</h3>
</div>
<div className={show}>
<ZakazAdmin/>
{/* <Spisok/> */}
</div>

</div>
        )
    }
export default Adminka;