// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header{
    background-color:rgb(79, 173, 232);
    width: 90vw;
    color: white;
    -webkit-text-decoration: solid gray 1px;
            text-decoration: solid gray 1px;
}

.logo{
    border-radius: 30px;
    background-color: gray;
    border: 1px solid gray;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,WAAW;IACX,YAAY;IACZ,uCAA+B;YAA/B,+BAA+B;AACnC;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".header{\n    background-color:rgb(79, 173, 232);\n    width: 90vw;\n    color: white;\n    text-decoration: solid gray 1px;\n}\n\n.logo{\n    border-radius: 30px;\n    background-color: gray;\n    border: 1px solid gray;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
