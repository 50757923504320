import React, {useCallback, useEffect, useState} from 'react';
import './Menu/menu.css'
import {useTelegram}  from '../components/hooks/useTelegram'


const Oplata = () => {

const {tg} = useTelegram();
    const [oplata, setOplata] = useState('')
    const pay='pay'
  const b=tg.initDataUnsafe?.user

    const onSendData = useCallback(() => {
        const data = {
            oplata,
            pay
        }
        tg.sendData(JSON.stringify(data));
    }, [ oplata, pay])   

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])
    
   
useEffect(() => {
    if (oplata==='') {
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
}, [oplata]) 
   

    function Click(){
        setOplata('ОПЛАТА')
      
    }

    function ClicNot(){
        setOplata('НЕ ОПЛАТИВ')
    }

    return (
        <div>
<h1>{oplata}</h1>
<h1>{b}</h1>
<button onClick={Click}>Оплатив</button>
<button onClick={ClicNot}>Не олатив</button>
        </div>

        )
    }
export default Oplata;