import './Footer.css'
import { useEffect } from 'react'
import {useTelegram} from '../hooks/useTelegram'
import { useState, useCallback } from 'react';


function Footer(props){

return(
<div className="footer">

<hr/>
<p>*всі послуги надаються згідно діючого закону України про права користувачів фізичною особою-підприємецем Коноваленко Тетяна Миколаївна </p>
<p>юридична адреса: 61075, м.харків, франтишка крала, буд. 37, кв. 21</p> 
<p>фактична адреса: м.харків, вул. данилевського, 26</p>
<p>тел. +38 (095)587 03 03, +38 (097) 396 70 01 </p>
<p>код єдрпоу 3152006824</p>
<p>iban: ua313510050000026007879202138 в ат “укрсиббанк”</p> 
<p>мфо 351005</p>
<hr/>

</div>

)
}

export default Footer