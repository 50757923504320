// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inform{
    width: 90vw;
    margin-left: 5vw;

    padding-bottom: 10vh;
}

.BPNRuls{
   background-color:  rgb(79, 173, 232, 0);
   border: none;
   text-align: left;
   
}

.none{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Inform.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;;IAEhB,oBAAoB;AACxB;;AAEA;GACG,uCAAuC;GACvC,YAAY;GACZ,gBAAgB;;AAEnB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".inform{\n    width: 90vw;\n    margin-left: 5vw;\n\n    padding-bottom: 10vh;\n}\n\n.BPNRuls{\n   background-color:  rgb(79, 173, 232, 0);\n   border: none;\n   text-align: left;\n   \n}\n\n.none{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
