import React, {useCallback, useEffect, useState} from 'react';
import {useTelegram} from './hooks/useTelegram';
import FileUpload from "./FileUpload";




const Answ = () => {

    const {tg} = useTelegram();
    const [id, setid] = useState('id')
    const [que, setque] = useState('m');
    const [col, setcol] = useState('p');
    const answer='3'

    const onChangeid = (e) => {
        setid(e.target.value)
    }
    const onChangeque = (e) => {
        setque(e.target.value)
    }
    const onChangecol= (e) => {
        setcol(e.target.value)
    }
   
    const onSendData = useCallback(() => {
        const data = {
           answer,
           id,
           col
           
        }
        tg.sendData(JSON.stringify(data));
    }, [ answer, col, id])   
    
    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])
    
    useEffect(() => {
        if (answer==='3') {
            tg.MainButton.show();
        } else {
            tg.MainButton.hide();
        }
    }, [answer])
    
    useEffect(() => {
           tg.MainButton.setParams({
           text: 'НАДІСЛАТИ'

    })})
 
    return (
        <div className={"formS"}>

            <h5>id</h5>   
            <textarea
                className='input'
                type="textarea"
                placeholder={'id клієнта'}
                value={id}
                onChange={onChangeid}
                
            />
    <h5> Вшдповідь</h5>   
            <textarea
                className='input2'
                type="textarea"
                placeholder={'Відповідь адміністратора'}
                value={col}
                onChange={onChangecol}  
            />

            <FileUpload/>
        </div>
    );
};

export default Answ;