import './Forma.css'
import { useState, useEffect, useCallback} from 'react';
import {useTelegram} from '../hooks/useTelegram'

const useInput=(initialValue, validations)=>{
    const [value, setvalue]=useState(initialValue)
    const [isDirty, setDirty]=useState(false)
    const valid=useValidation(value, validations)

    const onChange = (e) => {
        setvalue(e.target.value)
    }

   const onBlur=(e)=>{
        setDirty(true)
    }

    return{
        value,
        onChange,
        onBlur, 
        isDirty,
        ...valid
    }
}

const useValidation=(value, validations)=>{

const [isEmpty, setEmpty]=useState(true);
const [minLengthError, setMinLengthError]=useState(false);
const [isPhone, setPhoneError]=useState(true)
const [isBday, setBday]=useState(true)
const [isBmonth, setBmonth]=useState(true)
const [isByer, setByer]=useState(true)
const [MaxLengthError, setMaxLengthError]=useState(true)
const [isChildren, setChildren]=useState(true)
const [inputValid, setInputValid]=useState(false)

    useEffect(()=>{
for(const validation in validations){

switch(validation){
    case 'minLength':
value.length<validations[validation]? setMinLengthError(true):setMinLengthError(false)
        break;
    case   'isEmpty':
        value?setEmpty(false):setEmpty(true)
        break; 
    case 'isPhone':
        const rphone=/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
        rphone.test(String(value).toLowerCase())?setPhoneError(false):setPhoneError(true)
        break;

    case 'isBday':
            const rday=/^(0?[1-9]$)|(^1[0-9]$)|(^2[0-9]$)|(^3[0-1]$)/
            rday.test(String(value).toLowerCase())?setBday(false):setBday(true)
        break
    case 'isBmonth':
            const rmonth=/(^0?[1-9]$)|(^1[0-2]$)/
            rmonth.test(String(value).toLowerCase())?setBmonth(false):setBmonth(true)
        break
    case 'isByer':
            const ryers=/^(191[0-9]|19[5-9]\d|200\d|201\d|202[0-4])$/
            ryers.test(String(value).toLowerCase())?setByer(false):setByer(true)
        break         
    case 'MaxLengthError':
            value.length>validations[validation]? setMaxLengthError(true):setMaxLengthError(false)
        break
     case 'isChildren':
            const rChildren=/^(200[8-9]|201\d|202[0-4])$/
            rChildren.test(String(value).toLowerCase())?setChildren(true):setChildren(false)
        break                
}
}
    }, [value])
    useEffect(()=>{
        if(isEmpty, minLengthError, isPhone, isBday, isBmonth, isByer){
            setInputValid(false)
        } else{
            setInputValid(true)
        }
    }, [isEmpty, minLengthError,isPhone, isBday, isBmonth, isByer])

  

    return{
        isEmpty,
        minLengthError,
        isPhone,
        isBday, 
        isBmonth,
        isByer,
        MaxLengthError,
        isChildren,
        inputValid
    }

}




function Forma(){
    const [eksrtenno, setEksrtenno]=useState('eksrtennoBlue') 
    const [checked, setChecked] = useState(false)
    const [checkedNo, setCheckedNo] = useState(false)
    const [checkedZ, setCheckedZ]=useState(false)
    const [checkedP, setCheckedP]=useState(false)
    const name=useInput('', {isEmpty:true, minLength:3})
    const phone=useInput('', {isEmpty:true, minLength:9, MaxLengthError:13, isPhone:false}, )
    const bday=useInput('', {isEmpty:true, minLength:2,isBday:false})  
    const bmonth=useInput('', {isEmpty:true, minLength:2,isBmonth:false})  
    const byer =useInput('', {isEmpty:true, minLength:4,isByer:false, isChildren:false}) 
    const password=useInput('', {isEmpty:true, minLength:3})
    const forma='FORMA'
    const {tg} = useTelegram();
    const [document1, setDocument1]=useState('dA')
    const [document2, setDocument2]=useState('dA')  
    const [form, setForm]=useState('forma')

    function ekstr(){
        const a='buttonNextDA'
        setEksrtenno(a)
    }

    const onSendData = useCallback(() => {
    const data = {
        name,
        phone,
        bday,
        bmonth,
        byer,
        password,
        forma
    }
    tg.sendData(JSON.stringify(data));
}, [ name, phone,bday,bmonth,byer,password, forma])   


useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData)
    return () => {
        tg.offEvent('mainButtonClicked', onSendData)
    }
}, [onSendData])

useEffect(() => {
    tg.MainButton.setParams({
        text: 'ЗАРЕЄСТРУВАТИСЯ'
    })
}, [])

useEffect(() => {
    if( !checkedZ|| !checkedP ) {
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
}, [checkedZ, checkedP])

    function handleChange() {
        setChecked(!checked); 
        const checkedNo=false
        setCheckedNo(checkedNo)
    }
    function handleChangeNo() {
        setCheckedNo(!checkedNo); 
        const checked=false
        setChecked(checked)
    }

    function handleChangeZ(){
        setCheckedZ(!checkedZ);  
    }
    function handleChangeP(){
        setCheckedP(!checkedP);  
    }

function x(){
    const a='dA'
    const b='forma'
    setDocument1(a)
    setDocument2(a)
    setForm(b)
}
function showZgoda(){
    const a='doc'
    const b='dA'
    setDocument1(a) 
    setForm(b)
}
function showUmova(){
    const a='doc'
    const b='dA'
    setDocument2(a) 
    setForm(b)
}


return(
<>
<div className={eksrtenno}>
<h4>Для отримання медичних послуг,  будь ласка зареєструйтеся.</h4>
    <button className="exrtBTNBlue" onClick={ekstr}>Далі</button>
</div>

<div className={document1}>
<button  className='clouse' onClick={x}></button>
<h5>ТЕКСТ!!!!!</h5>
</div>
<div className={document2}>
<button  className='clouse' onClick={x}></button>
<h5>ТЕКСТ!!!!!</h5>
</div>
<div className={form}>


    <div className='blockforma'>

    <h2 className='reestr'>
            Реєстрація
    </h2>
    <p className='pinput'>Ваші ПІБ</p>
    {(name.isDirty&&name.isEmpty)&&<div className='BlockError'> Поле не може бути пустим</div>}
        <input
                className='inputData'
                onBlur={e=>name.onBlur(e)}
                onChange={e=>name.onChange(e)}
                name='name'
                type="text"
                placeholder={'Прізвище та і`мя'}
                value={name.value}  
            />
    </div>
    <div className='blockforma'>
        <p className='pinput'>Номер телефону</p>
        {(phone.isDirty&&phone.isEmpty)&&<div className='BlockError'>Поле не може бути пустим</div>}
         {(phone.isDirty&&phone.isPhone)&&<div className='BlockError'> Номер телефону не може містити літери і має почінатися з коду країни</div>} 
        <input
                className='inputData'
                onBlur={e=>phone.onBlur(e)}
                onChange={e=>phone.onChange(e)}
                name='phone'
                type="text"
                placeholder={'Контактний номер телефону'}
                value={phone.value}  
           
            />
    </div>
    <div className='blockforma'>
        <p className='pinput'>Дата народження</p>
        {(bday.isDirty&&bday.isEmpty||bmonth.isDirty&&bmonth.isEmpty||byer.isDirty&&byer.isEmpty)&&<div className='BlockError'> Поле не може бути пустим</div>}
{(bday.isDirty&&bday.isBday||bmonth.isDirty&&bmonth.isBmonth||byer.isDirty&&byer.isByer)&&<div className='BlockError'> Не вірний формат дати</div>}  
{(byer.isDirty&&byer.isChildren)&&<p className='TextChildren'>Обслугогування можливе лише в прісутності батьків!</p>}  
<section>
           
              <input
                className={'inputMin'}
                onBlur={e=>bday.onBlur(e)}
                onChange={e=>bday.onChange(e)}
                name='bday'
                type="text"
                placeholder={'День'}
                value={bday.value}  
            />
          
              <input
                className={'inputMin'}
                onBlur={e=>bmonth.onBlur(e)}
                onChange={e=>bmonth.onChange(e)}
                name='bmonth'
                type="text"
                placeholder={'Місяць'}
                value={bmonth.value}  
            /> 
           
              <input
                className={'inputMidl'}
                onBlur={e=>byer.onBlur(e)}
                onChange={e=>byer.onChange(e)}
                name='byer'
                type="text"
                placeholder={'Рік народження'}
                value={byer.value}  
            />  
 </section>
    </div>
    <div className='blockStat'>
        <p className='pinput'>Стать</p>
        <div className='checkboxBox'>
            <input
          className='checkbox'
          type="checkbox" 
          checked={checked} 
          onChange={handleChange} 
           /> 
		 
         <div className='checkboxText'><p>Жінка</p></div>
            </div> 
        
            <div className='checkboxBox'>
            <input
          className={'checkbox'}
          type="checkbox" 
          checked={checkedNo} 
          onChange={handleChangeNo} 
           /> 
         <div className='checkboxText'><p>Чоловік</p></div>
            </div>   



    </div>
    <br/>
    <div className='blockforma'>
        <div className='checkboxBoxLong'>
            <input
          className='checkbox'
          type="checkbox" 
          checked={checkedZ} 
          onChange={handleChangeZ} 
           /> 
         <div className='checkboxTextZ'><button  className='zgoda'onClick={showZgoda}>Даю згоду на обробку персональних даних</button></div>
            </div>
            <div className='checkboxBoxLong'>
            <input
          className='checkbox'
          type="checkbox" 
          checked={checkedP} 
          onChange={handleChangeP} 
           /> 
         <div className='checkboxTextZ'><button  className='zgoda'onClick={showUmova}>Погоджуюся з правилами використання сервісу</button></div>
            </div>  
    </div>
</div>

</>

)

}

export default Forma