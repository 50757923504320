import React, {useCallback, useEffect, useState} from 'react';

import './zakaz.css'

const PORT='https://smsdoctor.online'
const Port2='https://smsdoctor.online'


function ZakazClient (props) {
  const rt=props.id
  const [changeDataZakazS, setChangeDataZakazS]=useState('')

    const [data, setData]=useState('')
    const [idZakaz, setIdZakaz] = useState('');
    const [dataZakazS, setDataZakazS]=useState('');
    const[dataMenu, setDataMenu]=useState('')
    const [komuZakaz, setKomuZakaz] = useState('');
    const [poperediV, setPoperediV]= useState(''); 
    const [ nomeOther,setNomeOther]=useState('')
    const [likarZakaz, setLikarZakaz]=useState('')
    const [skargaS, setSkargaS]=useState('')
    const [perebigS, setPerebigS]=useState('')
    const [likuvannyS, setLikuvannyS]=useState('')
    const [anamnez,setAnamnez]=useState('')
    const [dataPS, setDataPS]=useState('')
    const [timePS, setTimePS]=useState('')
    const [sposibS, setSposibS]=useState('')
    const [recommendationsS, setRecommendationsS]=useState('')
    const [examinationS, setExaminationS]=useState('')
    const [komentarS, setKomentarS]=useState('')
    const [komButton, setKomButton]=useState('Додати')
    const [ulPacient, setUlPacient]=useState('none')
    const [namePacient, setNamePacient]=useState('pacient')
    const [blank, setBlank]=useState('none')
    const[factor, setFactor]=useState('')
    const [colorZakaz, setColorZakaz]=useState('options')
    const [status, setStatus]=useState('нова заявка')
    const[buttonOptions, setButtonOptions]=useState('none')
    const[buttonDoctors, setButtonDoctors]=useState('none')
    const[doctorZakaz, setDoctorZakaz]=useState(' ')
    const [newData, setNewData]=useState(dataPS)
    const [clientId, setClientId]=useState('')
    const defaultValue=[]
    const [apl, setApl]=useState(defaultValue)
    const [openBlosk, setOpenBlosk]=useState('none')
    const [dataLastZakaz, setDataLastZakaz]=useState('none')
    const [r,setR]=useState('none')


const onChangeidZakaz= (e) => {
  setIdZakaz(e.target.value)
}

// useEffect(() => {
//   if (rt==='cc') {
//    setBlank('none')
//   } else {
      
//   }
// }, [rt])

    function ClickZakaz(event){
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          
          "action": 3,
           "id" : idZakaz, 
        }),
    })
        .then(response => response.json())
        .then(response => {
          const data=response[0]
          if(data===undefined){
           alert ('Ви ввели не коректний номер замовлення!')
           setBlank('none')
          }else{
          const a=data.id
       
          const z=data.DataToDay
          const b=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+z[8]+z[9]
          
          const c=data.menu
          const d=data.komu

          const e=data.pacient
          const f=data.likar
                  const l=data.perebig
          const m=data.likuvanny
          const anamnez=data.anamnez
          
  
          
     
          const bbb=Number(data.dia)
       
          const v=data.time
          const w=data.sposibText
          const zi=data.name+'\n'+""+data.bday
          const li=data.aplicativos
          const factors=data.factor
       
          const st=data.changeData
    
          const vvv=st.slice(4,25)

          setSkargaS(data.skarga)
          setR(a)
          setColorZakaz(data.color)
          setChangeDataZakazS(vvv) 
          setBlank('blank')
          setDoctorZakaz(data.doctor)
          setKomuZakaz(data.komu)
          setLikarZakaz(data.likar)
          setDataZakazS(b)
          setLikuvannyS(data.likuvanny)
          setPerebigS(data.perebig)
          setExaminationS(data.examination)
          setRecommendationsS(data.recommendations)
          setKomentarS(data.komentar)
          setIdZakaz(' ')
          if(bbb<15){
            const jj=Number(Math.round(z[8]+z[9]))
            const vv=Number (jj+bbb)
            const t=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+vv
            setDataPS(t)
          }else{
            setDataPS(data.dia)
          }
            
              setTimePS(v)



     

            if (e==='undefined'){

              setNamePacient(zi)
              setNomeOther(li)
             
            }else{
            setNamePacient(e) 
            setNomeOther(li)
            }
         
          }
      
        })
    }

    return (

      <div>
{/* <div  className='fixx' onClick={c}></div> */}
       <div className='firstblank'>
      Номер замовлення:  <input className='inputId'
      placeholder={'id замовлення'}
      value={idZakaz}
      onChange={onChangeidZakaz}/>

 <button className='buttonId' onClick={ClickZakaz}>переглянути</button>


 <div className={blank}>

<div className='blockZakaz'><div className='blockSkarga'>
<div className='rrr' > <div className='colorblock'><section className={colorZakaz}> </section></div> <div className='ryd'>№ {r}. {komuZakaz} | {likarZakaz} | {doctorZakaz}</div></div>
</div>
</div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Дата звернення: {dataZakazS} | Внесено зміни: {changeDataZakazS}</p>
<p>
  Дата консультації:  
  {dataPS}
 | 
   Час консультації:  
{timePS}
</p>
</div>
</div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Скарги пацієнта: </p>
<p>
{skargaS}
</p>
</div></div>
<div className='blockZakaz'>
<div className='blockPacient'>

<p>Попереднє лікування:</p>
<p>{likuvannyS}
</p>
</div>
<div className='blockPacientInfo'>
<p>Ефективність попереднього лікування: </p>
<p> {perebigS}
 </p>


</div>
</div>

<div className='blockZakaz'>
<div className='blockRecomenda'>

<p>Рекомендації що до обстеження:</p>
<p>{examinationS}</p>

</div>
<div className='blockPacientLik'>
<p>Рекомендації що до лікування: </p>
<p>{recommendationsS}</p>
</div>
</div>

<div className='blockZakaz'><div className='blockSkarga'>
<p>Коментар</p>

<p>{komentarS}</p>

</div></div>

{/* <div className='blockZakaz'><div className='blockSkarga'>



{/* </div></div>

</div>
<div className='selectDiv'>

</div>
</div>
</div>


<div className='blockZakaz'><div className='blockSkarga'>
<p>Скарги пацієнта: </p>
<p>
{skarga}
</p>
</div></div>
<div className='blockZakaz'>
<div className='blockPacient'>

<p>Попереднє лікування:</p>
<p>{likuvanny}
</p>
</div>
<div className='blockPacientInfo'>
<p>Ефективність попереднього лікування: </p>
<p> {perebig}
 </p>


</div>
</div> */}

{/* <div className='blockZakaz'><div className='blockSkarga'>
<p>Попередній висновок:</p>
<p>
{poperediV}
</p>
</div></div>

<div className='blockZakaz'>
<div className='blockRecomenda'>

<p>Рекомендації що до обстеження:</p>
<p>{examination}</p>

</div>
<div className='blockPacientLik'>
<p>Рекомендації що до лікування: </p>
<p>{recommendations}</p>
</div>
</div>

<div className='blockZakaz'><div className='blockSkarga'>
<p>Коментар</p>

<p>{komentar}</p>

</div></div> */}



</div>
 </div>

</div>



        )
    }
export default ZakazClient ;