import React, {useCallback, useEffect, useState} from 'react';

import './zakaz.css';
// const PORT='https://smsdoctor.online'
const Port2='https://smsdoctor.online'


function Spisok (props) {

  const defaultValue=[]
    const [apl, setApl]=useState(defaultValue)

    function ClickData(event){
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          "action": 9,
  
        }),
    })
    .then(response => response.json())
    .then(response => {
   
   


      const li = response.map((response) => (
  
        <div>  
          <p  className='btnLiColor' key={response.id}>
   <div className={response.color}></div> <div className=' cc'>{response.dia} {response.time} | № {response.id}  | {response.menu} | {response.doctor}</div>
        </p>
      
          </div>


            ))
          
  setApl(li);

    })

    }
 
    function ClickDataColorGrey(event){
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          "action": 10,
          "color":"grey"
        }),
    })
    .then(response => response.json())
    .then(response => {

    const li = response.map((response) => (
  
      <div>  
        <p  className='btnLiColor' key={response.id}>
   <div className={response.color}></div> <div className=' cc'>{response.dia} {response.time} | № {response.id}  | {response.menu} | {response.doctor}</div>
        </p>
    
        </div>


          ))
          setApl(li)

    })

    }

    function ClickDataColorBlue(event){
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          "action": 10,
          "color":'light-blue'


        }),
    })
    .then(response => response.json())
    .then(response => {

    const li = response.map((response) => (
  
      <div>  
      <p  className='btnLiColor' key={response.id}>
   <div className={response.color}></div> <div className=' cc'>{response.dia} {response.time} | № {response.id}  | {response.menu} | {response.doctor}</div>
        </p>
    
        </div>


          ))
          setApl(li)

    })

    }
    function ClickDataColorGreen(event){
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          "action": 10,
          "color":'green'


        }),
    })
    .then(response => response.json())
    .then(response => {

    const li = response.map((response) => (
  
      <div>  
      <p  className='btnLiColor' key={response.id}>
   <div className={response.color}></div> <div className=' cc'>{response.dia} {response.time} | № {response.id}  | {response.menu} | {response.doctor}</div>
        </p>
    
        </div>


          ))
          setApl(li)

    })

    }
    return (

      
      <div>






<div className='blank'>

<div className='menuSpisok'>
<button className='buttonMS' onClick={ClickData}>Замовлення</button>
<button className='buttonMS' onClick={ClickDataColorGrey}>Нові замовлення</button>
<button className='buttonMS' onClick={ClickDataColorBlue}>Розподідені замовлення</button>
<button className='buttonMS' onClick={ClickDataColorGreen}>Виконані замовлення</button>
{/* <p> <div onClick={ClickDataColor}>Закриті замовлення</div></p> */}</div>

<hr/> 
  
    {apl}


   




</div>

</div>

        )
    }
export default Spisok;