// fileupload.js

import React, { useRef, useState } from 'react';
import axios from 'axios';
import './Menu/menu.css'

function FileUpload(props) {
  const id=props.id
  // для хранения загруженного файла
  const [file, setFile] = useState('');
  const [filePath, setFilePath]=useState('');
  
  // для хранения ответа от бекенда
  const [data, getFile] = useState({ id:`${id}`, name: "", path: "" });

  const [progress, setProgess] = useState(0); // progessbar
  const el = useRef(); // для доступа к инпуту

  const handleChange = (e) => {
    setProgess(0)
    const file = e.target.files[0]; // доступ к файлу

    console.log(file);
    setFile(file); // сохранение файла

  }

  const uploadFile = () => {
  
    const formData = new FormData();
  
    formData.append('file', file); // добавление файла
    axios.post('https://9a45-2a01-14-8065-9fc0-8c3d-ea7-89e8-f614.ngrok-free.app/upload', formData, {
      onUploadProgress: (ProgressEvent) => {
        let progress = Math.round(
          ProgressEvent.loaded / ProgressEvent.total * 100
        ) + '%';
       if(progress<100){
        setProgess(progress);
       }else{
        setProgess('Додано, за необхідності додайте ще файли ')
        setFilePath(data.path)
        setFile('')
       }

      }
    }).then(res => {
      console.log(res);
 
      getFile({
        name: res.data.name,
        path: 'https://9a45-2a01-14-8065-9fc0-8c3d-ea7-89e8-f614.ngrok-free.app' + res.data.path,
        id: id
      })

  
    }).catch(err => console.log(err))
  }

  return (
    <div>
      <div className="file-upload">

        <input type="file" ref={el}  onChange={handleChange} />

     
        <button onClick={uploadFile} className="upbutton">
        Додати
        </button>
        {/* style={{ width: progress } */}
        <div className="progessBar" >
          {progress}
        </div>
        <hr />
        {/* для показа полученного изображения */}
        {data.path && <img src={data.path} alt={data.name}  width="50"
  height="50"/>}
      </div>
    </div>
  );
}

export default FileUpload;