import React from "react";
import { useState } from "react";
import './menu.css'
import StepOneConsulta from '../Consulta/StepOneConsulta'
import StepAnalizConsulta from "../Consulta/StepAnalizConsulta";
import StepNextConsulta from "../Consulta/StepNextConsulta";
import Check from "../ChecUpp/Check";

const PORT='https://smsdoctor.online'
function Menu(){
    
const [t1, setT1] = useState('');
const [menu, setMenu]=useState('menu')
const [menu2, setMenu2]=useState('none')
const[blockMini1, setBlockMini1]=useState('none')
const[blockMini2, setBlockMini2]=useState('none')
const [div, setDiv]=useState('')
const id=t1
function backMenu(){
    const a='none'
    const b='menu'
    setMenu(b)
    setMenu2(a) 
}

function hendler(){
    const a='none'
    setMenu(a)
    const b='menu'
    setMenu2(b)
}



function obst(){
    const a='none'
    setMenu(a) 
    const b=<Check id={t1}/>
    setDiv(b)}

function first(){
    
    const a='none'
    setMenu(a) 
    const b=<StepOneConsulta id={id}/>
    setDiv(b)   
}

function nextC(){
    const a='none'
    setMenu(a) 
    const b=<StepNextConsulta id={id}/>
    setDiv(b)
}

function analiz(){
    const a='none'
    setMenu(a) 
    const b=<StepAnalizConsulta id={id}/>
    setDiv(b)

}
function consulta (event) {
    const b='blockMini'
    setBlockMini1(b)
    const c='none'
    setBlockMini2(c)  
    event.preventDefault();
    fetch(PORT, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ 
        "action": 1,
         "menu" : "КОНСУЛЬТАЦІЯ ЛІКАРЯ",
         
      }),
  })
      .then(response => response.text())
      .then(response => {
          console.log(response);
          setT1(response.toString());
      })
  }


return(
    <>
{/* <div className={menu}>

<button className='blockBig' onClick={hendler}>
<h3>ПОСЛУГИ</h3>
    </button>
<button className='blockBig'>
<h3>СТРАХУВАННЯ ЗДОРОВ`Я</h3>
    </button>
    <button className='blockBig'>
<h3>ОНЛАЙН КУРСИ</h3>
    </button>
    <button className='blockBig'>
<h3>YOUTUBE</h3>
    </button>
    <button className='blockBig'>
<h3>ПОДКАСТ</h3>
    </button>  
    <button className='blockBig'>
<h3>ІНШЕ</h3>
    </button>   
</div> */}     
<div className={menu}>

<button className='blockBig' onClick={consulta}>
<h3>КОНСУЛЬТАЦІЯ ЛІКАРЯ</h3>
    </button>
    <div className={blockMini1}>
    <button className='btnMini' onClick={first}>
     <div className="left">- ПЕРВИННА КОНСУЛЬТАЦІЯ </div> <div className="right">800грн</div> 
        </button>
        <button className='btnMini' onClick={nextC}>
        <div className="left">- ПОВТОРНА КОНСУЛЬТАЦІЯ </div> <div className="right">500грн</div> 
        </button>
        <button className='btnMini' onClick={analiz}>
        <div className="left">- ЗА РЕЗУЛЬТАМИ АНАЛІЗІВ </div> <div className="right">800грн</div> 
    
        </button>
    </div>

    <button className='blockBig' onClick={obst}>
<h3>ІНДИВІДУАЛЬНА ПРОГРАММА ОБСТЕЖЕННЯ</h3> </button>
    <div className={blockMini2}>
    </div>
    <button className='blockBigGrey'>
<h3>Е-РЕЦЕПТ</h3>
    </button>
    <button className='blockBigGrey'>
<h3>АПТЕКИ</h3>
    </button>  
    <button className='blockBigGrey'>
<h3>МЕДИЧНИЙ ТУРИЗМ</h3>
    </button>   

</div>
<div className='bigb'>
{div}

<div className="pustota"></div>
</div>

</>
)
}

export default Menu