import './Header.css'
import { useEffect } from 'react'
import {useTelegram} from '../hooks/useTelegram'
import { useState, useCallback } from 'react';


function Header(props){

return(
<div className="header">

<h1>Правова інформація</h1>
{/* <button className='logo' >
    SMS
</button> */}

{/* <Nav  nav={props.props.nav}/> */}

</div>

)
}

function Nav(props) {

    let data = props.nav;
    const listItem = data.map(item => <li key={item.link}><a href={item.link}>{item.text}</a></li>);
    return (
        
        <nav>
            <ul>
                {listItem}
            </ul>
        </nav>
    )
}

export default Header