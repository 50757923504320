
import React, { useState, useCallback,useEffect, useRef} from "react";
import { useTelegram } from "../hooks/useTelegram";
import '../Menu/menu.css'
import FileUpload from "../FileUpload";
const PORT='https://smsdoctor.online'


function  Check(props){
    const[imtText, setImtText]=useState('')
    const [textN, setTextN]=useState('')
    const [t1, setT1] = useState('');
    const y=t1
    const [clientIdZakaz, setClientIdZakaz]=useState('')
    const [t2, setT2] = useState('');
    const [consultaTime, setConsultaTime]=useState('dataConsulta')
    const [consultaTime1, setConsultaTime1]=useState('dataConsulta')
    const [consultaTime2, setConsultaTime2]=useState('dataConsulta')
    const [consultaTime3, setConsultaTime3]=useState('dataConsulta')
    const [consultaPrioritet, setConsultaPrioritet]=useState('consultaPrioritet')
    const [consultaData, setConsultaData]=useState('dataConsulta')
    const [consultaData1, setConsultaData1]=useState('dataConsulta')
    const [consultaData2, setConsultaData2]=useState('dataConsulta')
    const [consultaData3, setConsultaData3]=useState('dataConsulta')
    const [consultaData4, setConsultaData4]=useState('dataConsulta')
    const [consultaData5, setConsultaData5]=useState('dataConsulta')
    const [consultaData6, setConsultaData6]=useState('dataConsulta')
    const [consultaData7, setConsultaData7]=useState('dataConsulta')
    const [consultaData8, setConsultaData8]=useState('dataConsulta')
    const [consultaData9, setConsultaData9]=useState('dataConsulta')
    const [consultaData10, setConsultaData10]=useState('dataConsulta')
    const [consultaData11, setConsultaData11]=useState('dataConsulta')
    const [consultaData12, setConsultaData12]=useState('dataConsulta')
    const [consultaData13, setConsultaData13]=useState('dataConsulta')
    const [consultaData14, setConsultaData14]=useState('dataConsulta')
    const today=new Date().getUTCDate()
    const month= new Date().getMonth()+1
    const textData=`${today}.0${month}`
    const textData1=`${today+1}.0${month}`
    const textData2=`${today+2}.0${month}`
    const textData3=`${today+3}.0${month}`
    const textData4=`${today+4}.0${month}`
    const textData5=`${today+5}.0${month}`
    const textData6=`${today+6}.0${month}`
    const textData7=`${today+7}.0${month}`
    const textData8=`${today+8}.0${month}`
    const textData9=`${today+9}.0${month}`
    const textData10=`${today+10}.0${month}`
    const textData11=`${today+11}.0${month}`
    const textData12=`${today+12}.0${month}`
    const textData13=`${today+13}.0${month}`
    const textData14=`${today+14}.0${month}`
    const goduna=new Date().getHours()
    const hvuluna= new Date().getMinutes()
    const [grn, setGrn]=useState('800')
    const sends='SYMPTOM'
    const [send, setSend]=useState('none')
    const [dia, setDia]=useState('')
    const [time, setTime]=useState('')
    const [timeNow, setTimeNow]=useState(goduna)
    const [IMTRost, setIMTRost]=useState('')
    const[IMTVaga, setIMTVaga]=useState('')
    const [habit, setHabit]=useState('btnMinilikar')
    const [habitData, setHabitData]=useState('')
    const [profR, setProfR]=useState('btnMinilikar')
    const [profRData, setProfRData]=useState('')
    const [faktors, setFaktors]=useState('btnMinilikar')
    const [factorsData, setFactorsData]=useState('')
    const[sport, setSport]=useState('btnMinilikar')
    const[stres, setStres]=useState('btnMinilikar')
    const[info, setInfo]=useState('btnMinilikar')
    const ir=dia.toString()
    const [chSt, setChSt]=useState('')
    const [IMT, setIMT]=useState('21')
    const [backStart, setBackStart]=useState('backBTN')
    const [textBTN, setTextBTN]=useState('Далі ➡️')
    const [BTNBig, setBTNBig]=useState('BTNBig')
    const [strocaDoctor, setStrocaDoctor]=useState('stroca')
    const [strocaPediatr, setStrocaPediatr]=useState('none') 
    const [back, setBack]=useState('none')
    const [doctor,setDoctor]=useState('btnMinilikarActive')
    const [terapevt, setTerapevt]=useState('btnMinilikar')
    const [gastro, setGastro]=useState('btnMinilikar')
    const [kardio, setKardio]=useState('btnMinilikar')
    const [endo, setEndo]=useState('btnMinilikar')
    const [derma, setDerma]=useState('btnMinilikar')
    const [ginek, setGinek]=useState('btnMinilikar')
    const [spec, setCpec]=useState('за симптомами')
    const [pediatr, setPediatr]=useState('btnMinilikar')
    const[bebyDermatolog, setBebyDermatolog] =useState('btnMinilikar')
    const [osteo, setOsteo]=useState('btnMinilikar')
    const [other, setOther]=useState('btnMinilikar')
    const [one, setOne]=useState('btnMinilikar')
    const [inputDiv, setInputDiv]=useState('none')
    const [inputDiv1, setInputDiv1]=useState('none')
    const [inputDiv2, setInputDiv2]=useState('none')
    const [inputDiv3, setInputDiv3]=useState('none')
    const [inputDivStep, setInputDivStep]=useState('none')
    const [inputDivStep1, setInputDivStep1]=useState('none') 
    const [inputDivStep2, setInputDivStep2]=useState('none')


    const [five, setFive]=useState('btnMinilikar')  
    const [fo, setFo]=useState('btnMinilikar') 
    const [thry, setThry]=useState('btnMinilikar')
    const [two, setTwo]=useState('btnMinilikar')
    const [likyes, setLikyes]=useState('btnMinilikaR')
    const [likno, setLikno]=useState('btnMinilikaR')
    const [inputDivLik, setInputDivLik]=useState('none')
    const [good, setGood]=useState('btnMinilikaR')
    const [bad, setBad]=useState('btnMinilikaR')
    const [normal, setNormal]=useState('btnMinilikaR')
    const [inputDinamika, setInputDinamika]=useState('none')
    const [step0, setStep0]=useState('blockGrande') 
    const [step1, setStep1]=useState('none')
    const [step2, setStep2]=useState('none')
    const [step3, setStep3]=useState('none')  
    const [step4, setStep4]=useState('none') 
    const [step5, setStep5]=useState('none')  
    const [consultaBig, setConsultaBig]=useState('btnMinilikar')
    const [consultaBebe, setConsultabebe]=useState('btnMinilikar')
    const [consultaMe, setConsultaMe]=useState('btnMinilikarActive')

    const [formaBebe, setFormaBebe]=useState('none')
    const [formaBig, setFormaBig]=useState('none')
    const [men, setMen]=useState('btnMinilikar')
    const [women, setWomen]=useState('btnMinilikar')
const [rost, setRost]=useState('')
const [vaga, setVaga]=useState('')
    const {tg} = useTelegram();
    const [nome, setNome]=useState('')

    const [stat, setStat]=useState('')
    const [phone, setPhone]=useState('')
    const [skarga, setSkarga]=useState('')
    const [perebig, setPerebig]=useState('')
    const [perebigText, setPerebigText]=useState('')
    const [likyvannyYesorNo, setLikyvannyYesorNo]=useState('')
    const [likText, setLikText]=useState('')
    const [sposibText, setSposibText]=useState('')

    const [date, setDate] = useState();

    const [inputformaBebe, setInputformaBebe]=useState('inputformaBebe')
    const [inputformaData, setInputformaData]=useState('inputformaBebe')
    const [textNome, setTextNome]=useState('')
 const [textBack, setTextBack]=useState('')
    const [eksrtenno, setEksrtenno]=useState('eksrtennoBlue')
const [komu, setKomu]=useState('Програма обстеження для мене')
const [likipostiyno, setLikipostiyno]=useState('')
const [operacia,  setOperacia]=useState('')
const [hron, setHron]=useState('')
const [alergia, setAlergia]=useState('')
const [vagitnist ,setVagitnist]=useState('Ні')





const onChangeRost = (e) => {
    const a=e.target.value**2
    const c=(IMTVaga/a)*10000
    const l=+c.toFixed(1)

    
    setIMT(l)
    setIMTRost(a)
    setRost(e.target.value)
    if(l<16){
        setImtText("vflo")
    }
    if(l<18,5){
        setImtText("недостантя маса тіла")
    }else
    if(l<22.9){
        setImtText("нормальна маса тіла")
    }else
    if(l<24.9){
        setImtText("ризик ожиріння")
    }else
    if(l<29.9){
        setImtText("ожіріння 1 ступеня")
    }else
    if(l<44.9){
        setImtText("ожіріння 2 ступеня")
    }else
    {
        setImtText("ожіріння 3 ступеня")
    }
}


const onChangeVaga = (e) => {
   const a=e.target.value
   const c=(a/IMTRost)*10000
   const l=+c.toFixed(1)
   setIMT(l)
   setIMTVaga(a)
   setVaga(e.target.value)
   if(l<18,5){
    setImtText("недостантя маса тіла")
}else
if(l<22.9){
    setImtText("нормальна маса тіла")
}else
if(l<24.9){
    setImtText("ризик ожиріння")
}else
if(l<29.9){
    setImtText("ожіріння 1 ступеня")
}else
if(l<44.9){
    setImtText("ожіріння 2 ступеня")
}else
{
    setImtText("ожіріння 3 ступеня")
}

}

const onChangeProfRData = (e) => {
    setProfRData(e.target.value)
}
const onChangeFactorsData = (e) => {
    setFactorsData(e.target.value)
}
const onChangeAlergia = (e) => {
    setAlergia(e.target.value)
}
const onChangeHabitData = (e) => {
    setHabitData(e.target.value)
}

const onChangeHron = (e) => {
    setHron(e.target.value)
}

const onChangeLikiPostiyno = (e) => {
    setLikipostiyno(e.target.value)
}

const onChangeOperacia = (e) => {
   setOperacia(e.target.value)
}


const onChangeSposib= (e) => {
    setSposibText(e.target.value)

}

    const onChangeLik = (e) => {
        setLikText(e.target.value)
   
    }

    const onChangePerebig = (e) => {
        setPerebigText(e.target.value)
    }
    const onChangeSkarga = (e) => {
        setSkarga(e.target.value)
    }

    const onChangeNome=(e)=>{
    
        setNome(e.target.value) 
        if(e.target.value.length<2){
        const t='Будь ласка, напишіть коректні прізвище та і`мя'
        const b= 'inputformaBebered'
        setTextNome(t)
        setInputformaBebe(b)
        }else  {
        const t=''    
        const b= 'inputformaBebe'
        setInputformaBebe(b)
        setTextNome('')
        } 
    }

    const onChangeDate=(e)=>{
        setDate(e.target.value)
        const regexddmmyyyy = /^(0?[1-9]$)|(^1[0-9]$)|(^2[0-9]$)|(^3[0-1]$)/
     
        // if (regexddmmyyyy.test(date)===true) {
        //     const a=' '
        //     const b= 'inputformaBebe'
        //     setInputformaData(b)
        //     setTextData(a)
        // } else {
        //     const b= 'inputformaBebered'
        //     const a='Будь ласка, вкажить вірну дату народження в форматі день/місяць/рік нарождення'
        //     setInputformaData(b)
        //     setTextData(a)
        // }
    } 
    const onChangePhone=(e)=>{
        
        setPhone(e.target.value)
        
    } 
    function ekstr(event){
        const a='buttonNextDA'
        setEksrtenno(a)
    event.preventDefault();
    fetch(PORT, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ 
        "action": 1
      }),
  })
      .then(response => response.text())
      .then(response => {
          console.log(response);
          setT1(response.toString());
       
      })
    }


    function Steps (event) {
        event.preventDefault();
        fetch(PORT, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ 
            "action": 2,
             "id":    t1,
             "menu" : "ІНДИВІДУАЛЬНА ПРОГРАММА ОБСТЕЖЕННЯ",
             "komu" : komu,
             "pacient" : nome+ date+phone+stat,
             "date": date,
             "likar": spec, 
             "skarga": skarga ,
             "perebig": perebig + '/n'+perebigText, 
             "likyvan": likyvannyYesorNo + likText, 
             "operacia": operacia,
             "hron":hron,
             "alergia": alergia,
             "vagitnist": vagitnist, 
             "likiP": likipostiyno,
             "dia" : dia,
             "time":time,
             "sposibText":sposibText,
             "factor":"Зріст: "+rost+"/n"+"Вага:"+vaga+"/n"+"Шкідливі звички: "+habitData+"/n"+"Недостатня фізична активність: "+sport+"/n"+"рофесійні ризики: "+profRData+"/n"+"Хронічний стрес: "+stres+"/n"+"Інформаційне перенавантаження:"+info+"/n"+"Інше: "+factorsData
        }),
      })
          .then(response => response.text())
          .then(response => {
              console.log(response);
              setT2(response.toString());
              setClientIdZakaz(response.toString())
            
          })
       
    
            const a='blockGrande'
            const b='none'
            const l=''
            // const d='Редагувати'
            const m='⬅️ Назад' 
            if(step0==='blockGrande'){

                setStep0(b)
                setStep1(a)
                setTextBack(m)
                setBack('backBTN')
                setBackStart('none')
         
            }
            if(step1==='blockGrande'){
                setStep1(b)
                setStep2(a) 
                setTextBack(m)
        
            }
            if(step2==='blockGrande'){
                setStep2(b)
                setStep3(a) 
                setTextBack(m)
              
            }
            if(step3==='blockGrande'){
                setStep3(b)
                setStep4(a) 
                setTextBack(m)
              
            } 
            if(step4==='blockGrande'){
                setStep4(b)
                setStep5(a)
                setTextBack(m)
             

                // setTextBTN(d)
                
            }
            
            if(step5==='blockGrande'){
                setStep3(a)
                setStep0(a)
                setStep1(a)
                setStep2(a)
                setStep4(a)
                setTextBack(m)
                setBTNBig(b)
                setSend('yes')
                setTextBack(l)
                setChSt('redact')
            } 
        
      }
      
      
    const onSendData = useCallback(() => {
    const data = {
        sends,
        clientIdZakaz,
        grn
       
    }
    tg.sendData(JSON.stringify(data));
}, [ sends, clientIdZakaz, grn])   



useEffect(() => {
    tg.onEvent('mainButtonClicked', onSendData)
    return () => {
        tg.offEvent('mainButtonClicked', onSendData)
    }
}, [onSendData])

useEffect(() => {
    if (chSt==='') {
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
    }
}, [chSt])

useEffect(() => {
       tg.MainButton.setParams({
       text: 'НАДІСЛАТИ ЗАМОВЛЕННЯ'
    

})})

function ConsultaMe(){
    const a='Програма обстеження для мене'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='none'
    const d='stroca'
    setConsultaMe(b)
    setConsultaBig(c)
    setConsultabebe(c) 
    setFormaBebe(act) 
    setFormaBig(act)
    setStrocaPediatr(act)
    setStrocaDoctor(d)
    setKomu(a)
}

function ConsultaBebe(){
    const a='Програма обстеження для дитини'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='formaBebe'
    const v='none'
    const d='stroca'
    setConsultaMe(c)
    setConsultaBig(c)
    setConsultabebe(b)
    setFormaBebe(act)
    setFormaBig(v) 
    setStrocaPediatr(v)
    setStrocaDoctor(d)
    setKomu(a)
}
function ConsultaBig(){
    const a='Програма обстеження для іншого дорослого'
    const b='btnMinilikarActive'
    const c='btnMinilikar'
    const act='formaBebe'
    const v='none'
    const d='stroca'
    setConsultaMe(c)
    setConsultaBig(b)
    setConsultabebe(c) 
    setConsultaMe(c)
    setFormaBig(act)
    setFormaBebe(v) 
    setStrocaPediatr(v)
    setStrocaDoctor(d) 
    setKomu(a)
}
  
    function notDoctor(){
        const a='Загальний стан'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(b)
        setTerapevt(c)
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c) 
        setCpec(a) 
        setPediatr(c)
        setOsteo(c)
        setOther(c)
        setBebyDermatolog(c)
    }

    function Terapevt(){
        const a='Онкологічний скринінг'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(b) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setOsteo(c)
        setOther(c)
        setBebyDermatolog(c)
    }

    function Gastro(){
        const a='Здорове серце'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(b)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setOsteo(c)
        setOther(c)
        setBebyDermatolog(c)
    }
    function Kardio(){
        const a='Харчовий статус'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(b)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setOsteo(c)
        setOther(c)
        setBebyDermatolog(c)
    }

    function Endo(){
        const a='Ендокринний статус'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(b)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setPediatr(c)
        setOsteo(c)
        setOther(c)
        setBebyDermatolog(c)
    }
    function Derma(){
        const a='Чоловіче здоров`я'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(b)
        setGinek(c)
        setCpec(a)
        setOsteo(c)
        setOther(c)
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Ginek(){
        const a='Жіноче здоров`я'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(b)
        setCpec(a)
        setOsteo(c)
        setOther(c)
        setPediatr(c)
        setBebyDermatolog(c)
    }
    function Osteo(){
        const a='Остеопороз'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setOsteo(b)
        setOther(c)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }
    function Other(){
        const a='Інше'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setOsteo(c)
        setOther(b)
        setCpec(a)
        setPediatr(c)
        setBebyDermatolog(c)
    }

    function Pediatr(){
        const a='Педіатр'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setBebyDermatolog(c)
        setPediatr(b)
    }
    function BebyDermatolog (){
        const a='Дитячий дерматолог'
        const b='btnMinilikarActive'
        const c='btnMinilikar'
        setDoctor(c)
        setTerapevt(c) 
        setGastro(c)
        setKardio(c)
        setEndo(c)
        setDerma(c)
        setGinek(c)
        setCpec(a)
        setBebyDermatolog(b)
    }
    function Habit(){
        if(habit==='btnMinilikar'){
            const b='btnMinilikarActive'
            const c='inputDiv'
            setHabit(b)
            setInputDivStep(c)
        }else{
            const b='btnMinilikar'
            const c='none'
            setHabit(b)
            setInputDivStep(c)
        }
    }
    
    function ProfR(){
        if(profR==='btnMinilikar'){
            const b='btnMinilikarActive'
            const c='inputDiv'
            setProfR(b)
            setInputDivStep1(c)
        }else{
            const b='btnMinilikar'
            const c='none'
            setProfR(b)
            setInputDivStep1(c)
        }
    }
    function Faktors(){
        if(faktors==='btnMinilikar'){
            const b='btnMinilikarActive'
            const c='inputDiv'
            setFaktors(b)
            setInputDivStep2(c)
        }else{
            const b='btnMinilikar'
            const c='none'
            setFaktors(b)
            setInputDivStep2(c)
        }
    }
    
    function Sport(){
        if(sport==='btnMinilikar'){
            const b='btnMinilikarActive'
            setSport(b)
        }else{
            const b='btnMinilikar'
            setSport(b)
        }
    }
    function Stres(){
        if(stres==='btnMinilikar'){
            const b='btnMinilikarActive'
            setStres(b)
        }else{
            const b='btnMinilikar'
            setStres(b)
        }
    } 

    function Info(){
        if(info==='btnMinilikar'){
            const b='btnMinilikarActive'
            setInfo(b)
        }else{
            const b='btnMinilikar'
            setInfo(b)
        }
    }



    function One(){
    if(one==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setOne(b)
        setInputDiv(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setOne(b) 
        setInputDiv(c)

    }
}
function Two(){
    if(two==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setTwo(b)
        setInputDiv1(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setTwo(b) 
        setInputDiv1(c)

    }
}

function Thry(){
    if(thry==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setThry(b)
        setInputDiv2(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setThry(b) 
        setInputDiv2(c)

    }
}
function Fo(){
    if(fo==='btnMinilikar'){
        const b='btnMinilikarActive'
        const c='inputDiv'
        setFo(b)
        setInputDiv3(c)
    }else{
        const b='btnMinilikar'
        const c='none'
        setFo(b) 
        setInputDiv3(c)

    }
}

function Five(){
const a='Так'
const n='Ні'
    if(five==='btnMinilikar'){
        const b='btnMinilikarActive'
        setFive(b)
        setVagitnist(a)
    }else{
        const b='btnMinilikar'
        setVagitnist(n)
        setFive(b) 
    }
}
function LikYes(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a="Так, отримую"
    setLikyvannyYesorNo (a)
    setLikyes(b)
    setLikno(c)
    setInputDivLik(d)
}

function LikNo(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='none'
    const a="Ні, не отримую"
    setLikyvannyYesorNo (a) 
    setLikyes(c)
    setLikno(b)
    setInputDivLik(d)
}
function Good(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a='Мені вже краще' 
    setPerebig(a)
    setGood(b)
    setNormal(c)
    setBad(c)
    setInputDinamika(d)
}
function Bad(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='inputDiv'
    const a='Мені стало гірше' 
    setPerebig(a) 
    setGood(c)
    setNormal(c)
    setBad(b)
    setInputDinamika(d) 
}

function Normal(){
    const b='btnMinilikarActiveR'
    const c='btnMinilikaR'
    const d='none' 
    const a='Все без змін' 
    setPerebig(a)
    setGood(c)
    setBad(c)
    setNormal(b)
    setInputDinamika(d) 
}


//  function Steps(){
//     const a='blockGrande'
//     const b='none'
//     const l=''
//     const d='Редагувати'
//     const m='⬅️ Назад' 
//     if(step0==='blockGrande'){
//         setStep0(b)
//         setStep1(a)
//         setTextBack(m)
 
//     }
//     if(step1==='blockGrande'){
//         setStep1(b)
//         setStep2(a) 
//         setTextBack(m)

//     }
//     if(step2==='blockGrande'){
//         setStep2(b)
//         setStep3(a) 
//         setTextBack(m)
      
//     }
//     if(step3==='blockGrande'){
//         setStep3(b)
//         setStep4(a) 
//         setTextBack(m)
      
//     } 
//     if(step4==='blockGrande'){
//         setStep4(b)
//         setStep5(a)
//         setTextBack(m)
//         setTextBTN(d)
//     }
    
//     if(step5==='blockGrande'){
//         setStep3(a)
//         setStep0(a)
//         setStep1(a)
//         setStep2(a)
//         setStep4(a)
//         setTextBack(m)
//         setBTNBig(b)
//         setSend('yes')
//         setTextBack(l)
//     } 

// }
 function Back(){
    const a='⬅️ Назад' 
    const b='blockGrande'
    const c='none' 
    const v='Далі ➡️'
    if(step0==='blockGrande'){
      
         
    }
    if(step1==='blockGrande'){
     
        setStep1(c) 
        setStep0(b)
        setTextBack(a)
        setBack('none')
        setBackStart('backBTN')
    } 

    if(step2==='blockGrande'){
        setBackStart('none') 
        setTextBack(a)
        setStep1(b)
        setStep2(c) 
       

    }
    if(step3==='blockGrande'){
       
        setTextBack(a)
   
        setStep2(b)
        setStep3(c) 
    }
    if(step4==='blockGrande'){
       
        setTextBack(a) 
        setStep3(b)
        setStep4(c)
    }
    if(step5==='blockGrande'){
        setTextBack(a) 
        setTextBTN(v)
        setStep4(b)
        setStep5(c)
    } 
 }

 function Men(){
    if(men==='btnMinilikar'){
        const b='btnMinilikarActive'
        const a='btnMinilikar'
        const c='чоловіча'
        setStat(c)
        setMen(b)
        setWomen(a)

    }else{
        const b='btnMinilikar'
        const a='btnMinilikarActive'
        const c='жіноча'
        setStat(c)
        setMen(b)
        setWomen(a)

    }
}

function Women(){
    if(women==='btnMinilikar'){
        const b='btnMinilikarActive'
        const a='btnMinilikar'
        const c='жіноча'
        setStat(c)
        setWomen(b)
        setMen(a)

    }else{
        const b='btnMinilikar'
        const a='btnMinilikarActive'
        const c='чоловіча'
        setStat(c)
        setMen(a)
        setWomen(b)

    }
}




function dataConsulta(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c={textData}
    const e='dataConsultaGrey'
    const v ='consultaPrioritet'
    const goduna=new Date().getHours()
    setTimeNow(goduna+1)
      
            if(timeNow<5){
                setConsultaTime(a)
                setConsultaTime1(a)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v)  
            }else
            if(timeNow<8){
                setConsultaTime(e)
                setConsultaTime1(a)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v) 
            }else 
            if(timeNow<11){
                setConsultaTime(e)
                setConsultaTime1(e)
                setConsultaTime2(a)
                setConsultaTime3(a) 
                setConsultaPrioritet(v) 
            }else
            if(timeNow<14){
            setConsultaTime(e)
            setConsultaTime1(e)
            setConsultaTime2(e)
            setConsultaTime3(a) 
            setConsultaPrioritet(v) 
        }else
        if(timeNow>14){
            setConsultaTime(e)
            setConsultaTime1(e)
            setConsultaTime2(e)
            setConsultaTime3(e) 
            setConsultaPrioritet(v)  
        }
    setDia(c)
    setConsultaData(b)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
 





}


function dataConsulta1(){

    const c=1
    const v ='consultaPrioritet' 
    const a='dataConsulta'
    const b='dataConsultaActive'

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  
    setDia(c)

    
    setConsultaData(a)
    setConsultaData1(b)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta2(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=2
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  
   
    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(b)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta3(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=3
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(b)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta4(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=4
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(b)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta5(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=5
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(b)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta6(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=6
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(b)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta7(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=7
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(b)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta8(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=8
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(b)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta9(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=9
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(b)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta10(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=10
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(b)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta11(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=11
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(b)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(a)
}

function dataConsulta12(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=12

    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(b)
    setConsultaData13(a)
    setConsultaData14(a)
}
function dataConsulta13(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=13

    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(c)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(b)
    setConsultaData14(a)
}
function dataConsulta14(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c=14
    const v ='consultaPrioritet' 
    

    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(a) 
    setConsultaPrioritet(v)  

    setDia(14)
    setConsultaData(a)
    setConsultaData1(a)
    setConsultaData2(a)
    setConsultaData3(a)
    setConsultaData4(a)
    setConsultaData5(a)
    setConsultaData6(a)
    setConsultaData7(a)
    setConsultaData8(a)
    setConsultaData9(a)
    setConsultaData10(a)
    setConsultaData11(a)
    setConsultaData12(a)
    setConsultaData13(a)
    setConsultaData14(b)
}

function timeConsulta(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const r='1000'
    const t='Пріорітетна консультація'
    const v='dataConsultaGrey'


    if(consultaTime==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
    }else
    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    }else{
        setConsultaTime(a)
        setConsultaTime1(a)
        setConsultaTime2(a)
        setConsultaTime3(a)
    }
    setTime(t)
    setConsultaPrioritet(d)
    setGrn(r)
}
function timeConsulta1(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const v='dataConsultaGrey'

    const t='8:00-11:00'
    if(consultaTime==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
    }else
    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    } else{
        setTime(t)
        setConsultaTime(b)
        setConsultaTime1(a)
        setConsultaTime2(a)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }
  

}
function timeConsulta2(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const j='dataConsultaGrey'
    const t='11:00-14:00'
    const v='dataConsultaGrey'

    if(consultaTime1==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)

        if(consultaTime2==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v)
        }else
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaPrioritet(c)
            setConsultaTime(v)
            setConsultaTime1(v)
            setConsultaTime2(v) 
            setConsultaTime3(v)
        }
    }else{
        setTime(t)
        setConsultaTime(a)
        setConsultaTime1(b)
        setConsultaTime2(a)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }



}
function timeConsulta3(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive' 
    const t='14:00-17:00'
    const v='dataConsultaGrey'

    if(consultaTime2==='dataConsultaGrey'){
        setConsultaPrioritet(c)
        setConsultaTime(v)
        setConsultaTime1(v)
        setConsultaTime2(v)
        if(consultaTime3==='dataConsultaGrey'){
            setConsultaTime3(v)
        }else{
            setConsultaTime3(a) 
        }
    }
   else
    {
        setTime(t)
        setConsultaTime(a)
        setConsultaTime1(a)
        setConsultaTime2(b)
        setConsultaTime3(a)
        setConsultaPrioritet(c)
    }
    

}
function timeConsulta4(){
    const a='dataConsulta'
    const b='dataConsultaActive'
    const c ='consultaPrioritet'
    const d ='consultaPrioritetActive'
    const t='17:00-20:00'

if(consultaTime3==='dataConsultaGrey'){
    setConsultaPrioritet(c) 
} else{
    setTime(t) 
    setConsultaTime(a)
    setConsultaTime1(a)
    setConsultaTime2(a)
    setConsultaTime3(b) 
    setConsultaPrioritet(c)
}

}

    return(
<>
<div className={eksrtenno}>
<h4>Комплексне дослідження вашого організму за допомогою консультацій фахівців, лабораторних аналізів та інструментальної діагностики для визначення загальної картини вашого здоров'я.</h4>
    <button className="exrtBTNBlue" onClick={ekstr}>Далі</button>
</div>

<div className="BTNSET" >

    <button className={backStart}><h2><a href="/">⬅️</a></h2></button>
    <button className={back} onClick={Back} ><h2>{textBack}</h2></button>
    <button className={BTNBig} onClick={Steps}><h2>{textBTN}</h2></button>
</div>

<div autoFocus className={step0}>

1. Кому потрібна консультація?
<div className="stroca">
    <button className={consultaMe} onClick={ConsultaMe}>
    </button>
     <div className="leftl">Мені</div>
             </div>

        <div className='stroca'> <button className={consultaBebe} onClick={ConsultaBebe}>
        </button>
        <div className="leftl">Моїй дитині</div>
        </div>
   
    <div className={formaBebe}>
    <h5> ПІБ дитини</h5>
    <p color="red">{textNome}</p>
<input type='text'
className={inputformaBebe}
placeholder="Іванов Петро"
value=  {nome}  
onChange={e=>onChangeNome(e)}
/>

<h5> Дата народження дитини</h5>
<input type='text'
className={inputformaData}
placeholder="09.02.2020"
value={date}  
onChange={e=>onChangeDate(e)}
/>

<h5>Стать пацієнта</h5>
<div className='rydok'>
    <div className='strocamin'>
        <button className={men} onClick={Men}>
        </button>
        <div className="leftlR">Чоловіча</div>
        </div> 
   
        <div className='strocamin'>
        <button className={women} onClick={Women}>
        </button>
     <div className="leftlR">Жіноча</div> 
     </div> 

     </div>
    </div> 

        <div className='stroca'>
        <button className={consultaBig} onClick={ConsultaBig}>
        </button>
        <div className="leftl">Іншому дорослому</div>
        </div> 


        <div className={formaBig}>
    <h5> ПІБ пацієнта</h5>
    <p color="red">{textNome}</p>
<input type='text'
className={inputformaBebe}
placeholder="Петров Борис"
value=  {nome}  
onChange={e=>onChangeNome(e)}
/>

<h5> Дата народження пацієнта</h5>

<input type='text'
className={inputformaData}
placeholder="09.02.1987"
value={date}  
onChange={e=>onChangeDate(e)}
/>
<h5>Контактний номер пацієнта</h5>
<input type='text'
className='inputformaBebe'
placeholder="380990000000"
value={phone}  
onChange={e=>onChangePhone(e)}
/>
<div className='rydok'>
    <div className='strocamin'>
    <h5>Стать пацієнта</h5>
        <button className={men} onClick={Men}>
        </button>
        <div className="leftlR">Чоловіча</div>
        </div> 
   
        <div className='strocamin'>
        <button className={women} onClick={Women}>
        </button>
     <div className="leftlR">Жіноча</div> 
     </div> 

     </div>
    </div> 
    <div className="vstavka"></div>

</div>

<div className={step1}>
2.  Будь ласка, оберіть мету обстеження

<div className='stroca'>
    <button className={doctor} onClick={notDoctor}>
    </button>
     <div className="leftl">Загальний стан</div>
             </div>

        <div className={strocaDoctor}> <button className={terapevt} onClick={Terapevt}>
        </button>
        <div className="leftl">Онкологічний скринінг</div>
        </div>

        <div className={strocaDoctor}>
        <button className={gastro} onClick={Gastro}>
        </button>
        <div className="leftl">Здорове серце</div>
        </div> 

        <div className={strocaDoctor}>
        <button className={kardio} onClick={Kardio}>
        </button>
     <div className="leftl">Харчовий статус</div> 
     </div> 


      <div className={strocaDoctor}> 
        <button className={endo} onClick={Endo}>
        </button>
     <div className="leftl">Eндокринний статус</div> 
     </div> 

        <div className={strocaDoctor}>
        <button className={derma} onClick={Derma}>
        </button>
     <div className="leftl">Чоловіче здоров`я</div> 
     </div> 
     <div className={strocaPediatr}>
        <button className={pediatr} onClick={Pediatr}>
        </button>
     <div className="leftl">Педіатр</div> 
     </div>
     <div className={strocaPediatr}>
        <button className={bebyDermatolog } onClick={ BebyDermatolog }>
        </button>
     <div className="leftl">Дитячий дерматолог</div> 
     </div>
    
        <div className={strocaDoctor}>
        <button className={ginek} onClick={Ginek}>
        </button>
     <div className="leftl">Жіноче здоров`я
</div>
</div>
<div className={strocaDoctor}>
        <button className={osteo} onClick={Osteo}>
        </button>
     <div className="leftl">Остеопороз
</div>
</div>
<div className={strocaDoctor}>
        <button className={other} onClick={Other}>
        </button>
     <div className="leftl">Інше
</div>
</div>
<div className="vstavka"></div> 
</div>

{/* Які прояви хвороби були на початку, як змінювалися симптоми з часом і як Ви почуваєте себе зараз? */}
<div className={step2}>
3. Опишіть Ваші скарги, якщо маєте
    <div className='blockMini1'>
<textarea
className='inputTextB'
wrap="soft" 
name='skarga'
placeholder="Три дні тому з'явилася нежить
 та головний біль.Bчора з'явилася температура
  39 С, сьогодні непокоїть головний біль та
температура 37,8 С зранку"
value={skarga}  
onChange={e=>onChangeSkarga(e)}
/>
    </div>
    <div> <h5>🖇 За необхидности додайте фото та/або видео</h5>
    <FileUpload id={props.id}/></div>
               <div className="vstavka"></div> 

</div>


{/* 3. Опишіть перебіг та динаміку хвороби */}
<div className={step3}>
4. Фактори ризику

<h5>Зріст, см</h5>
<input type='text'
className='inputVR'
placeholder="170"
value=  {rost}  
onChange={e=>onChangeRost(e)}
/>

<h5>Вага, кг</h5>
<input type='text'
className='inputVR'
placeholder="60"
value={vaga}  
onChange={e=>onChangeVaga(e)}
/>
<div className="rangerIMT"><div style={{margin:`0 0 0 ${IMT*100/56}%`}} className="krug"></div></div>


<div className='stroca'> 
<div className="leftl">Ваш індекс маси тіла: {IMT}</div>  
     </div> 
<div className='stroca'> 
        <button className={habit} onClick={Habit}>
        </button>
     <div className="leftl">Шкідливі звички</div> 
     </div> 
     <div className={inputDivStep}>
     <textarea
     className='inputText'
     placeholder="паління, вживання алкоголю..."
     value=  {habitData}  
     onChange={e=>onChangeHabitData(e)} 
     /> 
     </div> 

     <div className='stroca'> 
        <button className={profR} onClick={ProfR}>
        </button>
     <div className="leftl">Професійні ризики</div> 
     </div> 
     <div className={inputDivStep1}>
     <textarea
     className='inputText'
     placeholder="паління, вживання алкоголю..."
     value=  {profRData}  
     onChange={e=>onChangeProfRData(e)} 
     /> 
     </div>

        <div className='stroca'> 
        <button className={sport} onClick={Sport}>
        </button>
        <div className="leftl">Недостатня фізична активність</div> 
             </div>   
             <div className='stroca'> 
        <button className={stres} onClick={Stres}>
        </button>
        <div className="leftl">Хронічний стрес</div> 
             </div>  
             <div className='stroca'> 
        <button className={info} onClick={Info}>
        </button>
        <div className="leftl">Інформаційне перенавантаження</div> 
             </div>    

             <div className='stroca'> 
        <button className={faktors} onClick={Faktors}>
        </button>
     <div className="leftl">Інше</div> 
     </div> 
     <div className={inputDivStep2}>
     <textarea
     className='inputText'
     placeholder="паління, вживання алкоголю..."
     value=  {factorsData}  
     onChange={e=>onChangeFactorsData(e)} 
     /> 
     </div>
</div>



{/*  Важливі питання */}
<div className={step4}>

5. Особливі стани(якщо є в наявності)

    <div className='stroca'> 
        <button className={one} onClick={One}>
        </button>
     <div className="leftl">Наявність алергії та непереносимість ліків</div> 
     </div> 
     <div className={inputDiv}>
     <textarea
     className='inputText'
     placeholder="алергія не пенецілін"
     value=  {alergia}  
     onChange={e=>onChangeAlergia(e)} 
     /> 
     </div>

     <div className='stroca'> 
        <button className={two} onClick={Two}>
        </button>
     <div className="leftl">Наявність хронічних захворювань</div> 
     </div> 
     <div className={inputDiv1}>
     <textarea
     className='inputText'
     placeholder="підвищений тиск"
     value=  {hron}  
     onChange={e=>onChangeHron(e)} 
     /> 
     </div>

     <div className='stroca'> 
        <button className={thry} onClick={Thry}>
        </button>
     <div className="leftl">Перенесені операції</div> 
     </div> 
     <div className={inputDiv2}>
     <textarea
     className='inputText'
     placeholder="видалена щитоподібна залоза"
     value=  {operacia}  
     onChange={e=>onChangeOperacia(e)} 
     /> 
     </div>
     <div className='stroca'> 
        <button className={fo} onClick={Fo}>
        </button>
        <div className="leftl">Прийом ліків на постійній основі</div> 
             </div> 
     <div className={inputDiv3}>
     <textarea
     className='inputText'
     placeholder="я приймаю постійно еутірокс 75 мг"
     value=  {likipostiyno}  
     onChange={e=>onChangeLikiPostiyno(e)

     } 
     /> 
     </div>
     <div className='stroca'> 
        <button className={five} onClick={Five}>
        </button>
        <div className="leftl">Вагітність та період лактації</div> 
             </div> 
             <div className="vstavka"></div>

</div>
<div className={step5}>
6. Оберіть бажані дату та час консультації
<h5>
        Дата
    </h5>
    <div className='blockData'>
        <button className={consultaData} onClick={dataConsulta}>{textData}</button>
        <button className={consultaData1} onClick={dataConsulta1}>{textData1}</button>
        <button className={consultaData2} onClick={dataConsulta2}>{textData2}</button>
        <button className={consultaData3} onClick={dataConsulta3}>{textData3}</button>
        <button className={consultaData4} onClick={dataConsulta4}>{textData4}</button>
        <button className={consultaData5} onClick={dataConsulta5}>{textData5}</button>
        <button className={consultaData6} onClick={dataConsulta6}>{textData6}</button>
        <button className={consultaData7} onClick={dataConsulta7}>{textData7}</button>
        <button className={consultaData8} onClick={dataConsulta8}>{textData8}</button>
        <button className={consultaData9} onClick={dataConsulta9}>{textData9}</button>
        <button className={consultaData10} onClick={dataConsulta10}>{textData10}</button>
        <button className={consultaData11} onClick={dataConsulta11}>{textData11}</button>
        {/* <button className={consultaData12} onClick={dataConsulta12}>{textData12}</button>
        <button className={consultaData13} onClick={dataConsulta13}>{textData13}</button>
        <button className={consultaData14} onClick={dataConsulta14}>{textData14}</button> */}
     </div>
   
     <h5>Час</h5>
     <div className="blockChas">
   
      <button className={consultaPrioritet} onClick={timeConsulta}>Пріорітетна консультація <span className="red">*</span></button> 
     <button className={consultaTime} onClick={timeConsulta1}>08.00-11.00</button>
        <button className={consultaTime1} onClick={timeConsulta2}>11.00-14.00</button>
        <button className={consultaTime2} onClick={timeConsulta3}>14.00-17.00</button>
        <button className={consultaTime3} onClick={timeConsulta4}>17.00-20.00</button>
    <p><span className="red">*</span>Пріорітетна консультація - консультація протягом двох годин. Ії вартість на 200грн дорожча.</p>

     </div>
     <h5>Яким способом Вам зручно отримати консультацію?</h5>
     {/* </div> */}
     <div className='inputDiv'>
     <textarea
     className='inputText'
     placeholder="За номером телефону: +380976664453"
     value=  {sposibText}  
     onChange={e=>onChangeSposib(e)} 
     /> 
     </div>
     <div className="vstavka"></div>

</div>
<div className="vstavka"></div>
<div className="vstavka"></div>
</>
    )
}

export default  Check