import React, {useCallback, useEffect, useState} from 'react';

import './zakaz.css'
import ZakazClient from './ZakazClient';

// const PORT='https://smsdoctor.online'
// const Port2='http://localhost:3500'
const Port2='https://smsdoctor.online'

function ZakazAdmin () {

  const [textB,  setTextB]=useState('Попередні консультації 🔽')
  // const login=props
  const [changeData, setChangeData]=useState('')
    const [id, setid] = useState('')
    const [data, setData]=useState('')
    const [dataId, setDataId] = useState('');
    const [dataZakaz, setDataZakaz]=useState('');
    const[dataMenu, setDataMenu]=useState('')
    const [komu, setKomu] = useState('');
    const [poperediV, setPoperediV]= useState(''); 
    const [ nomeOther,setNomeOther]=useState('')
    const [likar, setLikar]=useState('')
    const [skarga, setSkarga]=useState('')
    const [perebig, setPerebig]=useState('')
    const [likuvanny, setLikuvanny]=useState('')
    const [anamnez,setAnamnez]=useState('')
    const [dataP, setDataP]=useState('')
    const [timeP, setTimeP]=useState('')
    const [sposib, setSposib]=useState('')
    const [recommendations, setRecommendations]=useState('')
    const [examination, setExamination]=useState('')
    const [komentar, setKomentar]=useState('')
    const [komButton, setKomButton]=useState('Додати')
    const [ulPacient, setUlPacient]=useState('none')
    const [namePacient, setNamePacient]=useState('pacient')
    const [blank, setBlank]=useState('none')
    const[factor, setFactor]=useState('')
    const [color, setColor]=useState('grey')
    const [status, setStatus]=useState('нова заявка')
    const[buttonOptions, setButtonOptions]=useState('none')
    const[buttonDoctors, setButtonDoctors]=useState('none')
    const[doctor, setDoctor]=useState(' ')
    const [newData, setNewData]=useState(dataP)
    const [clientId, setClientId]=useState('')
    const defaultValue=[]
    const [apl, setApl]=useState(defaultValue)
    const [openBlosk, setOpenBlosk]=useState('none')
    const [dataLastZakaz, setDataLastZakaz]=useState('none')
    const [r,setR]=useState('none')
    const [onezakaz, setOnezakaz]=useState('none')
    const [anamnezBlock, setAnamnezBlock]=useState('blockPacientInfo')





const [test, setTest]=useState('nnnn')
    const [div, setDiv]=useState('')
    const options = [
      { value: 'new', label: 'нова заявка', click:ClickNew, colorBTN: 'grey'},
      { value: 'sendDoctor', label: 'передано лікарю', click: sendDoctor, colorBTN: 'light-blue' },
      { value: 'doctorOk', label: 'прийнято лікарем', click: doctorOk, colorBTN: 'blue'  },
      { value: 'doctorEnd', label: 'виконано лікарем', click: doctorEnd, colorBTN: 'light-green'  },
      { value: 'Chief', label: 'погоджено Сhief', click: Chief, colorBTN: 'green'  },
      { value: 'AdminOk', label: 'погоджено Admin' , click : AdminOk, colorBTN: 'wait' },
      { value: 'sendRes', label: 'надіслано пацієнту', click: sendRes, colorBTN: 'wait1' }
    ]
    const doctors = [
      { value: 'doctor1', label: 'Олександр Іванович', click:Doctor1},
      { value: 'doctor2', label: 'Тетяна Миколаївна', click:Doctor2},
      { value: 'doctor3', label: 'Тетяна Павлівна', click:Doctor3},  
    ] 


    const onChangeRecomendations = (e) => {
      setRecommendations(e.target.value)
  }
    const onChangeKomentar = (e) => {
        setKomentar(e.target.value)
    }
    
    const onChangeid = (e) => {
        setid(e.target.value)
    }
    const onChangeName = (e) => {
      setNamePacient(e.target.value)
  }

  const onChangeAnamnez=(e)=>{
    setAnamnez(e.target.value)
  }


  const onChangeSkarga=(e)=>{
    setSkarga(e.target.value)
  }
 const onChangeLikuvanny=(e)=>{
  setLikuvanny(e.target.value)
}
  const onChangePerebig=(e)=>{
    setPerebig(e.target.value)
  }
  const  onChangeFactor=(e)=>{
    setFactor(e.target.value)
  }
  const   onChangePoperediV=(e)=>{
    setPoperediV(e.target.value)
  }
 
  const   onChangeExamination=(e)=>{
    setExamination(e.target.value)
  } 

  const   onChangeDataConsulta=(e)=>{
    setDataP(e.target.value)
  }

  const   onChangeTimeConsulta=(e)=>{
    setTimeP(e.target.value)
  }   
    function doctorsBTN(){
      setButtonDoctors('buttonOptions')
    }

function Doctor1(){
  setDoctor('Олександр Іванович')
  setButtonDoctors('none') 
}
function Doctor2(){
  setDoctor('Тетяна Миколаївна')
  setButtonDoctors('none') 
}

function Doctor3(){
  setDoctor('Тетяна Павлівна')
  setButtonDoctors('none') 
}
    function zakazStatus(){
      setButtonOptions('buttonOptions')
    }

  function ClickNew(){
    setStatus('нова заявка') 
    setColor('grey')
    setButtonOptions('none')
  }

  function sendDoctor(){
    setStatus('передано лікарю') 
    setColor('light-blue')
    setButtonOptions('none')
  }

  function doctorOk(){
    setStatus('прийнято лікарем' )
    setColor('blue') 
    setButtonOptions('none')
  }
  function doctorEnd(){
    setStatus('виконано лікарем' ) 
    setColor('light-green')
    setButtonOptions('none')
  }


  function Chief(){
    setStatus('погоджено Сhief') 
    setColor('green')
    setButtonOptions('none')
  }


  function AdminOk(){
    setStatus( 'погоджено Admin') 
    setColor('wait')
    setButtonOptions('none')
  }

  function sendRes(){
    setStatus('надіслано пацієнту') 
    setButtonOptions('none')
    setColor('wait1')
    }


    function listData(){
      if(r==='none'){
        setR('openBlock')
        const b=<ZakazClient id={'cc'}/> 
        setDiv(b)

      }else{
        setR('none')
        const b=<ZakazClient id={'cc'}/> 
        setDiv(b) 
      }
      
      if(anamnezBlock==='blockPacientInfo'){
        setAnamnezBlock('none')
        setOnezakaz('blockPacientInfo')
      }else{
        setAnamnezBlock('blockPacientInfo')
        setOnezakaz('none')
        setR('none')

      }
        }

    function pacientClick(event){

      // if(anamnezBlock==='blockPacientInfo'){
      //   setAnamnezBlock('none')
      //   setOnezakaz('blockPacientInfo')
      // }else{
      //   setAnamnezBlock('blockPacientInfo')
      //   setOnezakaz('none')
      //   setR('none')

      // } 
    
      event.preventDefault();

      fetch(Port2, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify({ 
          "action": 8,
           "id" : clientId, 
        }),
    })
    .then(response => response.json())
    .then(response => {
   
   function BTNCLK(){
    alert("nnnnn")
   }


      const li = response.map((response) => (
       <p onClick={BTNCLK} key={response.id} className='btnLi' >
      № {response.id} {response.menu}
          </p>
            ))
          
  setApl(li);
    })


      if(ulPacient==='none'){
      const b='regText'
      const t='Попередні консультації 🔼'
      setTextB(t)
      setUlPacient(b)

      }
     else{
      const a='none'
      const t='Попередні консультації 🔽'
      setTextB(t)
      setUlPacient(a) 
     }
    }




    function Click(event){
  
        event.preventDefault();

        fetch(Port2, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: JSON.stringify({ 
            "action": 3,
             "id" : id, 
          }),
      })
          .then(response => response.json())
          .then(response => {
            const data=response[0]
            if(data===undefined){
             alert ('Ви ввели не коректний номер замовлення!')
             setBlank('none')
            }
            else{
       
            const a=data.id
            const z=data.DataToDay
            const b=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+z[8]+z[9]
            
            const c=data.menu
            const d=data.komu

            const e=data.pacient
            const f=data.likar
            const k=data.skarga
            const l=data.perebig
            const m=data.likuvanny
            const anamnez=data.anamnez
            const color=data.color
    

            const bbb=Number(data.dia)
         
            const v=data.time
            const w=data.sposibText
            const zi=data.name+'\n'+""+data.bday
            const li=data.aplicativos
            const factors=data.factor
            const doc=data.doctor
            const st=data.status

            const stt=data.changeData
    
            const vvv=stt.slice(4,25)
            const changeData=data.changeData
            

            setBlank('blank')
            setUlPacient('none') 
            setChangeData(vvv) 
            setClientId(data.ChatId)
     
            setDataId(a)
            setDataZakaz(b)
            setDataMenu(c)        
            setKomu(d)
            setLikar(f)
            setSkarga(k)
            setPerebig(l)
            setLikuvanny(m)
            setExamination(data.examination)
            setRecommendations(data.recommendations)
            setTest(a)

            if(color.length===0){
              setColor('grey')
            }else{
              setColor(color) 
            }


            if(doc.length===0){
              setDoctor("Доктор")
            }else{
              setDoctor(data.doctor)
            }
            if(st.length===0){
              setStatus("нова заявка")
            }else{
              setStatus(data.status)
            } 

        if(bbb<15){
          const jj=Number(Math.round(z[8]+z[9]))
          const vv=Number (jj+bbb)
          const t=z[0]+z[1]+z[2]+z[3]+'-'+z[5]+z[6]+'-'+vv
          setDataP(t)
        }else{
          setDataP(data.dia)
        }
          
            setTimeP(v)
            setSposib(w)

        

if(factors==='undefined'){
  const fact= data.anamnez
  setFactor(fact)
}else{
  const fact=data.factor + data.anamnez
  setFactor(fact)
}

            if(anamnez.length===0){
              const n="Перенесені операції: "+data.operacia+ "\n"+"Наявність алергії: "+data.alergia+"\n"+ "Хронічні захворювання:" + data.hron+"\n"+"Вагітність та період лактації: " + data.vagitnist+"\n"+ "Прийом ліків на постійній основі: " +data.likiP
              setAnamnez(n)
            }else{
setAnamnez(anamnez)
            }

              if (e==='undefined'){

                setNamePacient(zi)
                setNomeOther(li)
               
              }else{
              setNamePacient(e) 
              setNomeOther(li)
              }
           
            }
        
          })
      }

 function Edd(event){
  
    event.preventDefault();
    fetch(Port2, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({ 
        "action": 4,
        "id":  id,
        "komentar": komentar,
        "pacient": namePacient,
        'anamnez':anamnez, 
        'skargaAdmin': skarga,
        'likyvanny':likuvanny,
        'perebig':perebig,
        'factor': factor,
        'poperediV': poperediV,
        'status': status,
        'doctor':doctor,
        'dia':dataP,
        'time':timeP,
        'examination': examination,
        'recommendations': recommendations,
        'color':color
        // 'changeData': login
    }),
  })
      .then(response => response.text())
      .then(response => {
const a=response
if(a===id){
    alert('Коментар додано')
    setKomButton('Внести зміни')
}else if(a===0){
    alert('Коментар не додано, зверніться до адміністратора') 
}
else{
    alert('Коментар не додано, зверніться до адміністратора')  
}
      }  
 )}
    return (

      
      <div>

      <div className='firstblank'>
      Номер замовлення:  <input className='inputId'
      placeholder={'id замовлення'}
      value={id}
      onChange={onChangeid}/>

 <button className='buttonId' onClick={Click}>переглянути</button> <button className='buttonId' onClick={Edd}>підтвердити замовлення</button>
 
 </div>

<div className={blank}>

<div className='blockZakaz'><div className='blockSkarga'>
<div className='rrr' > <div className='colorblock'><section className={color}> </section></div> <div className='ryd'>№ {test}. {komu} | {likar} | {doctor}</div></div>
</div>
</div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Дата звернення: {dataZakaz} | Внесено зміни: {changeData}</p>
<div className='rrr'>
<div className='sss'>
<div className='selectDiv'>
<p>
Статус замовлення: <button className='statysZakaz' onClick={zakazStatus}>{status}  🔄</button>
</p>

<div className={buttonOptions}>
{options.map(item=>
  <section>
<button className={options} onClick={item.click}>{item.label}</button>
  </section>
)}


</div>

</div></div>
<div className='sss'><div className='selectDiv'>
<p>
Лікар: <button className='statysZakaz' onClick={doctorsBTN}>{doctor}  🔄</button>
</p>

<div className={buttonDoctors}>
{doctors.map(item=>
  <section>
<button onClick={item.click}>{item.label}</button>
  </section>
)}

</div>
</div></div>
</div>
<div className='selectDiv'>
<p>
  Дата консультації:   </p>
  <input className='timeData'
   placeholder={dataP}
   value={dataP}
   onChange={onChangeDataConsulta} 
   />
   <p>
   Час консультації:   </p>
  <input className='timeData' 
  placeholder={timeP}
  value={timeP}
  onChange={onChangeTimeConsulta}
  />
</div>
</div>
</div>
<div className='blockZakaz'>
<div className='blockPacient'>


<p><textarea className='regText'
    placeholder={namePacient}
    value={namePacient}
    onChange={onChangeName}/></p>

<p> <div className='infoP' onClick={pacientClick}>{textB}</div></p>


<div className={r}> 
<button className='closedClick' onClick={listData}>Х</button>
{div}
       </div> 
    <div className={ulPacient}> 
    <hr/> 
  <button onClick={listData}>Переглянути замовлення детальніше</button>
  {apl}
    </div>
  
</div>
<div className='blockPacientInfo'>
<p>   <textarea  rows="6" className='regText'
    placeholder= {anamnez}
    value={anamnez}
    onChange={onChangeAnamnez}/></p>

</div>


</div>

<div className='blockZakaz'><div className='blockSkarga'>
<p>Скарги пацієнта: </p>
<p><textarea  rows="5" className='regText'
    placeholder= {skarga}
    value={skarga}
    onChange={onChangeSkarga}/></p>
</div></div>
<div className='blockZakaz'>
<div className='blockPacient'>

<p>Попереднє лікування:</p>
<p><textarea  rows="5" className='regText'
    placeholder= {likuvanny}
    value={likuvanny}
    onChange={onChangeLikuvanny}/></p>
</div>
<div className='blockPacientInfo'>
<p>Ефективність попереднього лікування: </p>
<p><textarea  rows="5" className='regText'
    placeholder= {perebig}
    value={perebig}
    onChange={onChangePerebig}/></p>
</div>
</div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Додані матеріали: </p>
<button>Переглянути</button>
</div></div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Особливості стану здоров'я:</p>
<p>   <textarea  rows="6" className='regText'
    placeholder= {factor}
    value={factor}
    onChange={onChangeFactor}/></p>
</div></div>
<div className='blockZakaz'><div className='blockSkarga'>
<p>Попередній висновок:</p>
<p><textarea  rows="5" className='regText'
    placeholder= {poperediV}
    value={poperediV}
    onChange={onChangePoperediV}/></p>
</div></div>

<div className='blockZakaz'>
<div className='blockRecomenda'>

<p>Рекомендації що до обстеження:</p>
<p><textarea  rows="10" className='regText'
    placeholder= {examination}
    value={examination}
    onChange={onChangeExamination}/></p>

</div>
<div className='blockPacientLik'>
<p>Рекомендації що до лікування: </p>
<p><textarea  rows="10" className='regText'
    placeholder= {recommendations}
    value={recommendations}
    onChange={onChangeRecomendations}/></p>
</div>
</div>

<div className='blockZakaz'><div className='blockSkarga'>
<p>Коментар</p>

<p><textarea  rows="3" className='regText'
    placeholder= {komentar}
    value={komentar}
    onChange={onChangeKomentar}/></p>

</div></div>



</div>

</div>

        )
    }
export default ZakazAdmin;